import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateCityMutation,
  UpdateCityMutationVariables,
} from './generated/updateCity';

export const useMutationUpdateCity = () => {
  const data = useMutation<
    UpdateCityMutation,
    any,
    UpdateCityMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_CITY_MUTATION, params),
  });
  return data;
};

export const UPDATE_CITY_MUTATION = gql`
  mutation updateCity($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateCity(id: $id, parameters: $parameters) {
      id
      name
      latitude
      longitude
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
