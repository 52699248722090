import { gql } from 'graphql-request';
import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import {
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables,
} from './generated/updateSegment';

export const useMutationUpdateSegment = () => {
  const data = useMutation<
    UpdateSegmentMutation,
    any,
    UpdateSegmentMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_SEGMENT, params),
  });
  return data;
};

export const UPDATE_SEGMENT = gql`
  mutation updateSegment($id: Int, $parameters: [ConfigurationInput]) {
    updateSegment(id: $id, parameters: $parameters) {
      id
    }
  }
`;
