import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useRowModesModel } from 'components/DataGrid/hooks/useRowModesModel';
import { sxDisablePagination } from 'components/DataGrid/utils/sxDisablePagination';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { gqlSegments } from '../gql';
import { SegmentsQuery } from '../gql/generated/segments';
import { useMutationUpdateSegment } from '../gql/updateSegment';
import { Level } from './Level';

type Row = GridRowModel<NonNullable<SegmentsQuery['segments']>[0]>;

export const TableSegments = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const saveSegment = useMutationUpdateSegment();
  const { data, isLoading } = useQuery({ ...gqlSegments.segments });
  const {
    handleRowEditStop,
    handleRowModesModelChange,
    rowModesModel,
    getActions,
  } = useRowModesModel();

  const processRowUpdate = useCallback(
    (newRow: Row, oldRow: Row) => {
      const { id, reservations } = newRow;

      if (id && !isEqual(newRow, oldRow)) {
        saveSegment.mutate(
          {
            id,
            parameters: [
              // { key: 'days', type: 'Int', intValue: days },
              { key: 'reservations', type: 'Int', intValue: reservations },
            ],
          },
          {
            onSuccess: () => {
              enqueueSnackbar({
                message: t('message.success.changes-saved'),
                variant: 'success',
              });
            },
            onError: () => {
              enqueueSnackbar({
                message: t('message.error.error-occured'),
                variant: 'error',
              });
            },
          },
        );
        return newRow;
      }

      return oldRow;
    },
    [enqueueSnackbar, saveSegment, t],
  );

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.segments.id'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'symbol',
      headerName: t('pages.segments.symbol'),
      ...defaultProps,
      renderCell: (params) => <Level type={params.value}>{params.value}</Level>,
    },
    {
      field: 'enabled',
      type: 'boolean',
      headerName: t('pages.segments.enabled'),
      ...defaultProps,
    },
    {
      field: 'reservations',
      headerName: t('pages.segments.reservations'),
      type: 'number',
      editable: true,
      ...defaultProps,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      headerName: '',
      getActions,
      flex: 1,
    },
  ];

  return (
    <DataGrid
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnSorting
      columns={columns}
      rows={data?.segments || []}
      loading={isLoading}
      autoHeight
      editMode="row"
      processRowUpdate={processRowUpdate}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      sx={sxDisablePagination}
    />
  );
};
