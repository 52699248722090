import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetUserGroupQuery,
  GetUserGroupQueryVariables,
} from './generated/userGroup';

export const getUsersGroup = (props: GetUserGroupQueryVariables) =>
  gqlClient.request<GetUserGroupQuery>(GET_USER_GROUP, props);

export const GET_USER_GROUP = gql`
  query getUserGroup($id: Int!) {
    getUserGroup(id: $id) {
      id
      name
      permissions {
        id
        module
        action
        fullName
      }
    }
  }
`;
