import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateHotelMutation,
  UpdateHotelMutationVariables,
} from './generated/toggleHotelOptions';

export const useMutationToggleHotelOptions = () => {
  const data = useMutation<
    UpdateHotelMutation,
    any,
    UpdateHotelMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(TOGGLE_HOTEL_OPTIONS_MUTATION, params),
  });
  return data;
};

export const TOGGLE_HOTEL_OPTIONS_MUTATION = gql`
  mutation updateHotel($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateHotel(id: $id, parameters: $parameters) {
      taxEnabled
      expose {
        dedge
        google
      }
    }
  }
`;
