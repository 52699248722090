import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getRankingTypes } from './rankingTypes';
import { getRankingType } from './rankingType';
import { RankingTypeQueryVariables } from './generated/rankingType';
import { getOffers } from './offers';
import { OffersQueryVariables } from './generated/offers';
import { RankingTypesQueryVariables } from './generated/rankingTypes';

export const gqlRanking = createQueryKeys('ranking', {
  rankingTypes: (props: RankingTypesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getRankingTypes(props),
  }),
  rankingType: (props: RankingTypeQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getRankingType(props),
  }),
  offers: (props: OffersQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getOffers(props),
  }),
});
