import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridSortModel,
} from '@mui/x-data-grid';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@mui/material';
import { DefaultGridToolbar } from 'components/DataGrid';
import { Services } from '../gql/rankingTypes';

interface Props {
  handlePagination: (paginationData: {
    page: number;
    pageSize: number;
  }) => void;
  count: number;
  rows: any[];
  loading: boolean;
  offset: number;
  limit: number;
  activeRanking: Services;
  isDraft: boolean;
  onSortModelChange:
    | ((model: GridSortModel, details: GridCallbackDetails<any>) => void)
    | undefined;
}
export const GridRanking = forwardRef((props: Props, ref: any) => {
  const {
    handlePagination,
    onSortModelChange,
    count,
    rows,
    loading,
    offset,
    limit,
    activeRanking,
    isDraft,
  } = props;
  const { t } = useTranslation();

  const getColumnsDays = (service?: Services): GridColDef[] => {
    let name = '';
    if (isDraft) {
      switch (service) {
        case 'adjusted_provision_views':
          name = 'adjustedProvisionViewsDraftRanking';
          break;
        case 'availability_change':
          name = 'availabilityChangeDraftRanking';
          break;
        case 'availability':
          name = 'availabilityDraftRanking';
          break;
        case 'price_change':
          name = 'priceChangeDraftRanking';
          break;
        case 'provision':
          name = 'provisionDraftRanking';
          break;
        case 'provision_views':
          name = 'provisionViewsDraftRanking';
          break;
        case 'reservations':
          name = 'reservationsDraftRanking';
          break;
        case 'views':
          name = 'viewsDraftRanking';
          break;
        default:
          name = 'adjustedProvisionViewsDraftRanking';
          break;
      }
    } else {
      switch (service) {
        case 'adjusted_provision_views':
          name = 'adjustedProvisionViewsRanking';
          break;
        case 'availability_change':
          name = 'availabilityChangeRanking';
          break;
        case 'availability':
          name = 'availabilityRanking';
          break;
        case 'price_change':
          name = 'priceChangeRanking';
          break;
        case 'provision':
          name = 'provisionRanking';
          break;
        case 'provision_views':
          name = 'provisionViewsRanking';
          break;
        case 'reservations':
          name = 'reservationsRanking';
          break;
        case 'views':
          name = 'viewsRanking';
          break;
        default:
          name = 'adjustedProvisionViewsRanking';
          break;
      }
    }

    return [
      {
        field: isDraft ? 'rankingDraft' : 'ranking',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        renderHeader: () => (
          <Tooltip title={t('pages.ranking.popularity-tooltip')}>
            <Box textAlign="center">
              <Typography fontWeight={600}>
                {t('pages.ranking.ranking')}
              </Typography>
              <Typography fontSize={10} fontWeight={600}>
                {t('pages.ranking.popularity')}
              </Typography>
            </Box>
          </Tooltip>
        ),
      },
      {
        headerName: t('pages.ranking.ranking'),
        field: `${name}`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: true,
      },
      {
        headerName: t('pages.ranking.today'),
        field: `${name}0`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: true,
      },
      {
        headerName: t('pages.ranking.yesterday'),
        field: `${name}1`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: true,
      },
      {
        headerName: t('pages.ranking.3-days-ago'),
        field: `${name}3`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: true,
      },
      {
        headerName: t('pages.ranking.7-days-ago'),
        field: `${name}7`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: true,
      },
    ];
  };

  const columns: GridColDef[] = [
    {
      headerName: 'ID',
      field: 'id',
      width: 60,
    },
    {
      headerName: t('pages.ranking.name'),
      field: 'name',
      flex: 3,
      sortable: true,
    },
    {
      headerName: t('pages.ranking.hotel'),
      field: 'nameHotel',
      flex: 2,
      sortable: true,
    },
    ...getColumnsDays(activeRanking),
  ];

  return (
    <>
      {isDraft ? <h1>Draft data</h1> : null}
      <DataGrid
        ref={ref}
        disableRowSelectionOnClick
        disableColumnMenu
        columns={columns}
        rows={rows}
        loading={loading}
        autoHeight
        paginationMode="server"
        sortingMode="server"
        columnHeaderHeight={100}
        paginationModel={{ page: offset, pageSize: limit }}
        sortingOrder={['asc', 'desc']}
        onPaginationModelChange={handlePagination}
        rowCount={count || 0}
        density="compact"
        onSortModelChange={onSortModelChange}
        slots={{
          toolbar: DefaultGridToolbar,
        }}
      />
    </>
  );
});
