import { Grid } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { DefaultGridToolbar } from 'components/DataGrid';
import { gqlHotels } from './gql';

export const HotelOffers = () => {
  const [queryParams] = useSearchParams();
  const hotelId = queryParams?.get('hotelId') || '';
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 5);
  const [sortBy, setSortBy] = useQueryParamsState(
    'sortBy',
    'offer_translation.name',
  );
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'asc');
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    ...gqlHotels.getHotelOffers({
      id: hotelId,
      offset: offset * limit,
      limit,
      orderBy: sortBy,
      orderDir: sortDir,
    }),
  });

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    const { field, sort } = item || {};
    const getKeySort = () => {
      switch (field) {
        case 'name':
          return 'offer_translation.name';
        case 'id':
          return 'id';
        default:
          return field;
      }
    };

    if (sort) {
      setSortDir(sort);
    }

    if (field) {
      setSortBy(getKeySort());
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate(`/hotels/offers/${params.id}`);
  };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.offers.id'),
      ...defaultProps,
      minWidth: 90,
      maxWidth: 90,
      resizable: false,
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('pages.offers.name'),
      ...defaultProps,
      sortable: true,
      minWidth: 280,
      renderCell: (params) => (
        <a
          href={`https://triverna.pl${params.row.url}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'inherit' }}
        >
          {params.row.name}
        </a>
      ),
    },
    {
      field: 'migrationId',
      headerName: t('pages.offers.migrationId'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'rooms',
      headerName: t('pages.offers.rooms'),
      ...defaultProps,
      minWidth: 280,
      renderCell: (
        params: GridRenderCellParams<any, (typeof rows)[0]['rooms']>,
      ) => (
        <Grid
          container
          direction="column"
          style={{ padding: '8px', textAlign: 'left' }}
        >
          {params?.value?.map((room) => (
            <span key={room.id}>
              {room.translate.name} ({room.id})
            </span>
          ))}
        </Grid>
      ),
    },
    {
      field: 'startDate',
      headerName: t('pages.offers.start'),
      minWidth: 88,
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'endDate',
      headerName: t('pages.offers.end'),
      minWidth: 88,
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'active',
      headerName: t('pages.offers.active.header'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'updatedAt',
      headerName: t('pages.offers.update'),
      minWidth: 100,
      ...defaultProps,
      sortable: true,
    },
  ];

  const rows =
    data?.offers?.items?.map((row) => ({
      id: row.id,
      name: row.translate.name,
      migrationId: row.migrationId,
      rooms: row.rooms,
      startDate: row.startDate.split(' ')[0],
      endDate: row.endDate.split(' ')[0],
      active: row.active
        ? t('pages.hotels.active.active')
        : t('pages.hotels.active.inactive'),
      updatedAt: row.updatedAt.split(' '),
      url: row.url,
    })) || [];

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DataGrid
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            rows={rows}
            columns={columns}
            autoHeight
            getRowHeight={() => 'auto'}
            pageSizeOptions={[5, 10, 25, 50]}
            paginationMode="server"
            paginationModel={{ page: offset, pageSize: limit }}
            sortingOrder={['asc', 'desc']}
            sortingMode="server"
            onSortModelChange={onSortModelChange}
            slots={{
              toolbar: DefaultGridToolbar,
            }}
            onPaginationModelChange={handlePagination}
            rowCount={data?.offers.count || 0}
            onRowClick={handleRowClick}
            sx={{
              '.MuiDataGrid-cell': { display: 'flex', alignItems: 'center' },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
