import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateProvinceMutation,
  UpdateProvinceMutationVariables,
} from './generated/updateProvince';

export const useMutationUpdateProvince = () => {
  const data = useMutation<
    UpdateProvinceMutation,
    any,
    UpdateProvinceMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_PROVINCE_MUTATION, params),
  });
  return data;
};

export const UPDATE_PROVINCE_MUTATION = gql`
  mutation updateProvince($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateProvince(id: $id, parameters: $parameters) {
      id
      migrationId
      name
    }
  }
`;
