import { ReactNode } from 'react';
import { ChipProps } from '@mui/material';
import { GenericCardProps } from './root';

export enum NavActionType {
  FUNCTION = 'function',
  LINK = 'link',
}

export type NavActionProps = {
  type: NavActionType;
  label: string;
  function?: any;
  url?: string;
  target?: boolean;
  icon: GenericCardProps['iconPrimary'] | string;
};

export type NavItemProps = {
  caption?: ReactNode | string;
  children?: NavItemProps[];
  elements?: NavItemProps[];
  chip?: ChipProps;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  disabled?: boolean;
  external?: boolean;
  isDropdown?: boolean;
  icon?: GenericCardProps['iconPrimary'] | string;
  id?: string;
  search?: string;
  target?: boolean;
  type?: string;
  url?: string | undefined;
  actions?: NavActionProps[];
  name?: string;
};

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

export type MenuProps = {
  openedItem: string;
  openedComponent: string;
  openedHorizontalItem: string | null;
  isDashboardDrawerOpened: boolean;
  isComponentDrawerOpened: boolean;
};
