import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  RankingTypesQuery,
  RankingTypesQueryVariables,
} from './generated/rankingTypes';

export const getRankingTypes = (props: RankingTypesQueryVariables) =>
  gqlClient.request<RankingTypesQuery>(RANKING_TYPES, props);

export const RANKING_TYPES = gql`
  query rankingTypes($draft: Boolean) {
    rankingTypes(draft: $draft) {
      id
      name
      service
      configuration
      configurationDiff
      auditLogs
      relatedDraft {
        id
        name
        service
        configuration
      }
    }
  }
`;

export type Services =
  | 'adjusted_provision_views'
  | 'availability_change'
  | 'availability'
  | 'price_change'
  | 'provision'
  | 'provision_views'
  | 'reservations'
  | 'views'
  | 'main';
