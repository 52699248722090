import { ChangeEvent, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { Box, Grid, IconButton } from '@mui/material';
import { EditOutlined } from '@ant-design/icons';
import { Level } from 'pages/segments/components/Level';
import { DefaultGridToolbar } from 'components/DataGrid';
import { gqlUsers } from './gql';
import { EditContactDialog } from './components/EditContactDialog';

const emptyContact = {
  firstName: '',
  lastName: '',
  email: '',
};

export const Subscribers = () => {
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const [sortBy, setSortBy] = useQueryParamsState('sortBy', 'createdAt');
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'desc');
  const [openDialog, setOpenDialog] = useState(false);
  const [userId, setUserIdToEdit] = useState(0);
  const [userToEdit, setUserToEdit] = useState(emptyContact);
  const [searchPhrase, setSearchPhrase] = useState('');

  const { data, isLoading } = useQuery({
    ...gqlUsers.getUsersWithSubscription({
      offset: offset * limit,
      limit,
      orderBy: sortBy,
      orderDir: sortDir,
      phrase: searchPhrase,
    }),
  });

  const client = useQueryClient();
  const refetch = () =>
    client.refetchQueries({
      ...gqlUsers.getUsersWithSubscription({
        offset: offset * limit,
        limit,
        orderBy: sortBy,
        orderDir: sortDir,
        phrase: searchPhrase,
      }),
    });

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    if (!item) {
      setSortBy('name');
      setSortDir('desc');
      return;
    }
    setSortBy(item.field);
    setSortDir(item.sort || 'desc');
  };

  const handleCloseDialog = () => {
    setUserToEdit(emptyContact);
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSearchInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.preventDefault();
    setSearchPhrase(event.target.value);
  };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('pages.users.firstName'),
      ...defaultProps,
    },
    {
      field: 'lastName',
      headerName: t('pages.users.lastName'),
      ...defaultProps,
    },
    {
      field: 'email',
      headerName: t('pages.users.email'),
      ...defaultProps,
    },
    {
      field: 'symbol',
      headerName: t('pages.users.symbol'),
      ...defaultProps,
      renderCell: (params) => <Level type={params.value}>{params.value}</Level>,
    },
    {
      field: 'edit',
      headerName: t('pages.users.edit'),
      ...defaultProps,
      renderCell: () => (
        <IconButton onClick={handleOpenDialog}>
          <EditOutlined />
        </IconButton>
      ),
    },
  ];

  const rows =
    data?.contacts?.items?.map((row) => ({
      id: row.id,
      userId: row.user?.id,
      firstName: row.user?.id ? row.user?.firstName : row.firstName,
      lastName: row.user?.id ? row.user?.lastName : row.lastName,
      email: row.email,
      symbol: row.user?.segment?.symbol,
    })) || [];

  const setUser = (param: {
    id: any;
    row: {
      firstName: string;
      lastName: string;
      email: string;
      userId?: string;
    };
  }) => {
    const clickedUser = {
      firstName: param.row.firstName,
      lastName: param.row.lastName,
      email: param.row.email,
      userId: param.row.userId,
    };

    setUserIdToEdit(param.id);
    setUserToEdit(clickedUser);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <h2>{t('paths.subscribers')}</h2>
      </Grid>
      <Grid item>
        <Box>
          <SearchBar
            placeholder={t('pages.users.search-user')}
            value={searchPhrase}
            handleSearchInputChange={handleSearchInputChange}
          />
        </Box>
      </Grid>
      <Grid item width="100%">
        <DataGrid
          loading={isLoading}
          disableRowSelectionOnClick
          rows={rows}
          columns={columns}
          autoHeight
          pageSizeOptions={[10, 25, 50, 75]}
          paginationMode="server"
          paginationModel={{ page: offset, pageSize: limit }}
          onPaginationModelChange={handlePagination}
          rowCount={data?.contacts.count || 0}
          sortingMode="server"
          onSortModelChange={onSortModelChange}
          onRowClick={setUser}
          disableColumnMenu
          slots={{
            toolbar: DefaultGridToolbar,
          }}
        />
      </Grid>
      <EditContactDialog
        key={userId}
        open={openDialog}
        id={userId}
        handleCloseDialog={handleCloseDialog}
        initialValues={userToEdit}
        refetch={refetch}
      />
    </Grid>
  );
};
