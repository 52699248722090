import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import { ContactsQuery, ContactsQueryVariables } from './generated/contacts';

export const getUsersWithSubscription = (props: ContactsQueryVariables) =>
  gqlClient.request<ContactsQuery>(GET_USERS_WITH_SUBSCRIPTION, props);

export const GET_USERS_WITH_SUBSCRIPTION = gql`
  query contacts(
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDir: String
    $phrase: String
  ) {
    contacts(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
      conditions: [
        {
          column: "contact_user.id"
          operator: "isNotNull"
          value: ""
          type: "AND"
        }
      ]
    ) {
      items(phrase: $phrase) {
        firstName
        lastName
        email
        id
        user {
          id
          firstName
          lastName
          segment {
            symbol
          }
        }
      }
      count
    }
  }
`;
