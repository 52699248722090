import { Theme } from '@mui/material';

export function ListItemIcon(): Theme['components'] {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 24,
        },
      },
    },
  };
}
