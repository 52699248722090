import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { gqlSegments } from 'pages/segments/gql';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { User } from '../gql/types';
import { useMutationUpdateUser } from '../gql/updateUser';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  id: number;
  initialValues: User;
  refetch: () => void;
}

export const EditUsersDialog = ({
  open,
  handleCloseDialog,
  id,
  initialValues,
  refetch,
}: Props) => {
  const { mutate, isPending } = useMutationUpdateUser();
  const { t } = useTranslation();
  const { data } = useQuery({ ...gqlSegments.segments });

  const validationSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email('Invalid email'),
    phone: yup.string(),
    segment: yup.number(),
    loyaltyCardNumber: yup.string().notRequired(),
    active: yup.boolean().notRequired(),
  });

  const formik = useFormik<Props['initialValues']>({
    initialValues: {
      ...initialValues,
      segment:
        data?.segments?.find((item) => item.symbol === initialValues.segment)
          ?.id || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({
      active,
      email,
      firstName,
      lastName,
      loyaltyCardNumber,
      phone,
      segment = '',
    }) => {
      mutate(
        {
          id,
          parameters: [
            {
              key: 'firstName',
              stringValue: firstName,
              type: 'String',
            },
            {
              key: 'lastName',
              stringValue: lastName,
              type: 'String',
            },
            {
              key: 'email',
              stringValue: email,
              type: 'String',
            },
            {
              key: 'phone',
              stringValue: phone,
              type: 'String',
            },
            {
              key: 'loyaltyCardNumber',
              stringValue: loyaltyCardNumber,
              type: 'String',
            },
            {
              key: 'segment',
              type: 'Entity',
              entityType: 'segment',
              entityValue: Number(segment),
            },
            {
              key: 'active',
              boolValue: active,
              type: 'Boolean',
            },
          ],
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            refetch();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseDialog}>
      <DialogTitle>{t('editing')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid direction="column" container spacing={1}>
            <Grid item style={{ marginTop: '8px' }}>
              <TextField
                fullWidth
                label={t('pages.users.firstName')}
                name="firstName"
                id="firstName"
                value={formik.values.firstName || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <TextField
                fullWidth
                label={t('pages.users.lastName')}
                name="lastName"
                id="lastName"
                value={formik.values.lastName || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <TextField
                fullWidth
                label={t('pages.users.email')}
                type="email"
                name="email"
                id="email"
                value={formik.values.email || ''}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <TextField
                fullWidth
                label={t('pages.users.phone')}
                type="string"
                name="phone"
                id="phone"
                value={formik.values.phone || ''}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <FormControl fullWidth>
                <InputLabel id="type">{t('pages.users.segment')}</InputLabel>
                <Select
                  label={t('pages.users.segment')}
                  name="segment"
                  id="segment"
                  fullWidth
                  value={formik.values.segment}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.segment && Boolean(formik.errors.segment)
                  }
                >
                  {data?.segments?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.symbol}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <TextField
                fullWidth
                label={t('pages.users.loyaltyCardNumber')}
                name="loyaltyCardNumber"
                id="loyaltyCardNumber"
                value={formik.values.loyaltyCardNumber || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.loyaltyCardNumber &&
                  Boolean(formik.errors.loyaltyCardNumber)
                }
                helperText={
                  formik.touched.loyaltyCardNumber &&
                  formik.errors.loyaltyCardNumber
                }
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch />}
                label={t('pages.users.user-status')}
                onChange={formik.handleChange}
                checked={formik.values.active}
                name="active"
              />
            </Grid>
            <Grid container item spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  disabled={!formik?.isValid}
                  loading={isPending}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
