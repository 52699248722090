import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetOfferCalendarStaysQuery,
  GetOfferCalendarStaysQueryVariables,
} from './generated/getOfferCalendarStays';

export const getOfferCalendarStays = (
  props: GetOfferCalendarStaysQueryVariables,
) =>
  gqlClient.request<GetOfferCalendarStaysQuery>(
    GET_OFFER_CALENDAR_STAYS,
    props,
  );

export const GET_OFFER_CALENDAR_STAYS = gql`
  query getOfferCalendarStays(
    $id: Int!
    $groupSimilar: Boolean
    $roomId: Int
    $startDate: String
    $endDate: String
  ) {
    getOfferCalendarStays(
      id: $id
      input: {
        groupSimilar: $groupSimilar
        roomId: $roomId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      room {
        id
        translate {
          name
        }
      }
      startDate
      endDate
      minLos
      maxLos
      noArrival
      noDeparture
      price
      pricePromo
      adBedAdultPrice
      adBedChildPrice
      quantity
    }
  }
`;
