import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import { UsersQueryVariables, UsersQuery } from './generated/users';

export const getUsers = (props: UsersQueryVariables) =>
  gqlClient.request<UsersQuery>(GET_USERS, props);

export const GET_USERS = gql`
  query users(
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDir: String
    $conditions: [ConditionInput]
    $phrase: String
  ) {
    users(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
      conditions: $conditions
    ) {
      items(phrase: $phrase) {
        id
        firstName
        lastName
        migrationId
        active
        updatedAt
        identifier
        email
        phone
        loyalty
        fundsEnabledTotal
        loyaltyCardNumber
        segment {
          symbol
        }
      }
      count
    }
  }
`;
