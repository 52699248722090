import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';

export const DefaultGridToolbar = () => (
  <GridToolbarContainer sx={{ px: 2 }}>
    <GridToolbarColumnsButton />
  </GridToolbarContainer>
);
