import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'components/Loader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { paths } from 'routes/paths';
import { gqlUsers } from './gql';
import { FormUsersGroup } from './components/FormUsersGroup';
import { useMutationUpdateUserGroup } from './gql/updateUserGroup';
import { useMutationRemoveUserGroup } from './gql/removeUserGroup';

export const UpdateUsersGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState<number[]>([]);
  const [name, setName] = useState('');
  const id = Number(params.id);
  const { mutate, isPending } = useMutationUpdateUserGroup();
  const mutationRemove = useMutationRemoveUserGroup();
  const { data, isLoading, error } = useQuery({
    ...gqlUsers.getUsersGroup({
      id,
    }),
  });

  useEffect(() => {
    setValues(
      data?.getUserGroup?.permissions?.map((item) => item?.id || -1) || [],
    );
  }, [data]);

  const handleSave = () => {
    mutate(
      {
        id,
        parameters: [
          { key: 'name', type: 'String', stringValue: name },
          {
            key: 'permissions',
            type: 'Collection',
            entityType: 'permission',
            collectionValues: values,
          },
        ],
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            message: t('message.success.changes-saved'),
            variant: 'success',
          });
        },
      },
    );
  };

  const handleRemove = () => {
    mutationRemove.mutate(
      {
        id,
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            message: t('message.success.changes-saved'),
            variant: 'success',
          });
          navigate(paths.main.usersGroups);
        },
      },
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return 'Error';
  }

  return (
    <FormUsersGroup
      values={values}
      setValues={setValues}
      title={`${t('pages.users-groups.edit-group')} - ${data?.getUserGroup?.name}`}
      handleSave={handleSave}
      handleRemove={handleRemove}
      setName={setName}
      name={name}
      loadingSave={isPending}
      loadingRemove={mutationRemove?.isPending}
    />
  );
};
