import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getHotels } from './hotels';
import { HotelsQueryVariables } from './generated/hotels';
import { getHotelOffers } from './hotelOffers';
import { HotelOffersQueryVariables } from './generated/hotelOffers';
import { GetOfferCalendarStaysQueryVariables } from './generated/getOfferCalendarStays';
import { getOfferCalendarStays } from './getOfferCalendarStays';

export const gqlHotels = createQueryKeys('hotels', {
  getHotels: (props: HotelsQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getHotels(props),
  }),
  getHotelOffers: (props: HotelOffersQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getHotelOffers(props),
  }),
  getOfferCalendarStays: (props: GetOfferCalendarStaysQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getOfferCalendarStays(props),
  }),
});
export { getHotelOffers };
