import { Theme } from '@mui/material';

export function Checkbox(theme: Theme): Theme['components'] {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary[400],
        },
      },
    },
  };
}
