import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateProvinceMutation,
  CreateProvinceMutationVariables,
} from './generated/createProvince';

export const useMutationCreateProvince = () => {
  const data = useMutation<
    CreateProvinceMutation,
    any,
    CreateProvinceMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(CREATE_PROVINCE_MUTATION, params),
  });
  return data;
};

export const CREATE_PROVINCE_MUTATION = gql`
  mutation createProvince($parameters: [ConfigurationInput!]!) {
    createProvince(parameters: $parameters) {
      id
      migrationId
      name
    }
  }
`;
