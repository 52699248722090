import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutationCreateAdminUser } from '../gql/createAdminUser';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  refetch: () => void;
}

export const CreateAdminDialog = ({
  open,
  handleCloseDialog,
  refetch,
}: Props) => {
  const { mutate } = useMutationCreateAdminUser();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email('Invalid email'),
    password: yup.string(),
    active: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      active: false,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (value) => {
      mutate(
        {
          input: {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            password: value.password,
            active: value.active,
          },
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            refetch();
            formik.resetForm();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseDialog}>
      <DialogTitle>{t('pages.users.add-admin')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid direction="column" container spacing={3}>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.firstName')}
                name="firstName"
                id="firstName"
                value={formik.values.firstName || ''}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.lastName')}
                name="lastName"
                id="lastName"
                value={formik.values.lastName || ''}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.email')}
                type="email"
                name="email"
                id="email"
                value={formik.values.email || ''}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.password')}
                type="password"
                name="password"
                id="password"
                value={formik.values.password || ''}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch onChange={formik.handleChange} />}
                label={t('pages.users.user-status')}
                value="active"
                name="active"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent="flex-end">
            <Grid item>
              <LoadingButton
                disabled={!formik?.isValid}
                // loading={isPending}
                variant="contained"
                type="submit"
              >
                {t('save')}
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleCloseDialog}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
