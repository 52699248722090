import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DefaultGridToolbar } from 'components/DataGrid';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { gqlDictionaries } from './gql';

const WEBHOOK_LOGS_TYPES = [
  'offer',
  'gallery',
  'gallery_item',
  'offer_details',
  'reservation',
  'room',
  'hotel',
  'review',
  'preferences',
];

export const WebhookLogsDictionary = () => {
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useQueryParamsState(
    'sortBy',
    'webhook_log.createdAt',
  );
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'desc');
  const [typeFilter, setTypeFilter] = useQueryParamsState<string | null>(
    'type',
    null,
  );

  const { data, isLoading, isFetching } = useQuery({
    ...gqlDictionaries.getWebhookLogs({
      offset: offset * limit,
      orderBy: sortBy,
      orderDir: sortDir,
      limit,
      conditions: typeFilter
        ? [
            {
              column: 'webhook_log.type',
              value: typeFilter,
              operator: 'eq',
              type: 'and',
            },
          ]
        : undefined,
    }),
  });

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.webhooks.id'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'migrationId',
      headerName: t('pages.webhooks.migrationId'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'type',
      headerName: t('pages.webhooks.type'),
      ...defaultProps,
    },
    {
      field: 'createdAt',
      headerName: t('pages.webhooks.createdAt'),
      ...defaultProps,
    },
  ];

  const rows = data?.getWebhookLogs?.items?.map((row) => ({
    id: row.id,
    migrationId: row.migrationId,
    type: row.type,
    createdAt: row.createdAt,
  }));

  const onSortModelChange = ([item]: GridSortModel) => {
    if (!item) {
      setSortBy('webhook_log.id');
      setSortDir('asc');
      return;
    }
    setSortBy(`webhook_log.${item.field}`);
    setSortDir(item.sort || 'desc');
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setTypeFilter((event.target.value as string) || null);
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="type">{t('pages.webhooks.type')}</InputLabel>
              <Select
                onChange={handleTypeChange}
                value={typeFilter || ''}
                labelId="type"
              >
                <MenuItem value="">{t('all')}</MenuItem>
                {WEBHOOK_LOGS_TYPES.map((log) => (
                  <MenuItem key={log} value={log}>
                    {log}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading || isFetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            columns={columns}
            rows={rows || []}
            rowCount={data?.getWebhookLogs.count || 0}
            autoHeight
            pageSizeOptions={[10, 25, 50, 75]}
            paginationMode="server"
            paginationModel={{ page: offset, pageSize: limit }}
            onPaginationModelChange={handlePagination}
            sortingMode="server"
            onSortModelChange={onSortModelChange}
            slots={{
              toolbar: DefaultGridToolbar,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
