import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useUpdateOfferCalendarStays } from './gql/updateOfferCalendarStays';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  initialValues: any;
  offerId: number;
  refetch: () => void;
}

export const EditHotelOfferDetailsDialog = ({
  open,
  handleCloseDialog,
  initialValues,
  refetch,
  offerId,
}: Props) => {
  const { mutate, isPending } = useUpdateOfferCalendarStays();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    minLos: yup.number(),
    maxLos: yup.number(),
    noArrival: yup.boolean(),
    noDeparture: yup.boolean(),
  });

  const formik = useFormik<Props['initialValues']>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      mutate(
        {
          id: offerId,
          input: {
            startDate: initialValues.startDate,
            endDate: initialValues.endDate,
            rooms: [initialValues.id],
            minLos: values.minLos,
            maxLos: values.maxLos,
            noArrival: values.noArrival,
            noDeparture: values.noDeparture,
          },
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            refetch();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Edit Detail: {initialValues.name}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid container spacing={1}>
            <Grid container item spacing={1} direction="column">
              <Stack mt={2} direction="row" spacing={1}>
                <Grid item style={{ marginTop: '8px' }}>
                  <DatePicker
                    label="Start date"
                    readOnly
                    value={dayjs(initialValues.startDate)}
                  />
                </Grid>
                <Grid item style={{ marginTop: '8px' }}>
                  <DatePicker
                    label="End date"
                    readOnly
                    value={dayjs(initialValues.endDate)}
                  />
                </Grid>
              </Stack>
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="minLos"
                  name="minLos"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.minLos}
                  variant="standard"
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="maxLos"
                  name="maxLos"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.maxLos}
                  variant="standard"
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      checked={formik.values.noArrival}
                      onChange={formik.handleChange}
                      color="primary"
                      name="noArrival"
                    />
                  }
                  label="noArrival"
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      checked={formik.values.noDeparture}
                      onChange={formik.handleChange}
                      color="primary"
                      name="noDeparture"
                    />
                  }
                  label="noDeparture"
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  disabled={!formik?.isValid}
                  loading={isPending}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
