import {
  Autocomplete,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MainCard } from 'components/MainCard';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { gqlRanking } from '../gql';
import { useMutationPublishDraftMutation } from '../gql/publishRankingTypeDraft';
import { Form } from './Form';

interface Props {
  id: number;
  setRanking: Dispatch<SetStateAction<number>>;
  setOffset: Dispatch<SetStateAction<number>>;
  setOrderBy: Dispatch<SetStateAction<string | undefined>>;
  setOrderDir: Dispatch<SetStateAction<string | undefined>>;
  options: {
    id?: number | undefined;
    name: string;
    service: string;
    configuration?: any;
    configurationDiff?: any;
    relatedDraft?: {
      id?: number;
      name: string;
      service: string;
      configuration?: any;
    };
  }[];
  activeRanking: {
    id?: number | undefined;
    name: string;
    service: string;
    configuration?: any;
    configurationDiff?: any;
    relatedDraft?: {
      id?: number;
      name: string;
      service: string;
      configuration?: any;
    };
  };
}

export const EditRanking = (props: Props) => {
  const { t } = useTranslation();
  const {
    id,
    activeRanking,
    options,
    setRanking,
    setOffset,
    setOrderBy,
    setOrderDir,
  } = props;
  const { data, isFetching } = useQuery({
    ...gqlRanking.rankingType({ id }),
  });
  const { mutate } = useMutationPublishDraftMutation();

  const client = useQueryClient();
  const refetch = () =>
    client.refetchQueries({
      ...gqlRanking.rankingType({ id }),
    });

  const handlePublishRankingTypeDraft = (draftId: number) => {
    mutate(
      {
        id: draftId,
      },
      {
        onSuccess: () => {
          refetch();
          enqueueSnackbar({
            message: t('message.success.changes-saved'),
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar({
            message: t('message.error.error-occured'),
            variant: 'error',
          });
        },
      },
    );
  };

  const { fields, relatedDraft, configuration } = data?.rankingType || {};
  return (
    <MainCard sx={{ minHeight: 180 }}>
      <Typography fontWeight={600} sx={{ mb: 2 }}>
        {t('editing')} "{activeRanking?.name}"
      </Typography>
      <Autocomplete
        size="small"
        loading={options?.length === 0}
        disableClearable
        value={activeRanking}
        options={options}
        getOptionLabel={(item) => item.name}
        getOptionKey={(item) => item.id || 0}
        sx={{ width: 250, mb: 3 }}
        onChange={(_, value) => {
          setRanking(value?.id || 0);
          setOffset(0);
          setOrderBy('');
          setOrderDir('');
        }}
        renderInput={(params) => (
          <TextField {...params} label={t('pages.ranking.ranking')} />
        )}
      />
      {!isFetching && configuration && (
        <Grid container spacing={2} display="flex" direction="column">
          <Grid item>
            <Form
              fields={fields}
              initialValues={configuration}
              id={id}
              refetch={refetch}
            />
          </Grid>
          {relatedDraft ? (
            <>
              <Grid item>
                <Divider textAlign="center">Draft</Divider>
              </Grid>
              <Grid item>
                <Form
                  fields={fields}
                  initialValues={relatedDraft.configuration}
                  id={relatedDraft.id!}
                  isDraft
                  handlePublish={handlePublishRankingTypeDraft}
                  differents={data?.rankingType.configurationDiff}
                  refetch={refetch}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      )}
    </MainCard>
  );
};
