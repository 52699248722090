import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
} from './generated/createCategory';

export const useMutationCreateCategory = () => {
  const data = useMutation<
    CreateCategoryMutation,
    any,
    CreateCategoryMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(CREATE_CATEGORY_MUTATION, params),
  });
  return data;
};

export const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategory($parameters: [ConfigurationInput!]!) {
    createCategory(parameters: $parameters) {
      id
      migrationId
      defaultMonth
      defaultYear
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
