import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetCategoriesQuery,
  GetCategoriesQueryVariables,
} from './generated/getCategories';

export const getCategories = (props: GetCategoriesQueryVariables) =>
  gqlClient.request<GetCategoriesQuery>(GET_CATEGORIES, props);

export const GET_CATEGORIES = gql`
  query getCategories(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getCategories(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        migrationId
        defaultMonth
        defaultYear
        pl: translate(locale: "pl") {
          name
        }
        en: translate(locale: "en") {
          name
        }
      }
      count
    }
  }
`;
