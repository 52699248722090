import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { paths } from 'routes/paths';
import { FormUsersGroup } from './components/FormUsersGroup';
import { useMutationCreateUserGroup } from './gql/createUserGroup';

export const AddUsersGroup = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [values, setValues] = useState<number[]>([]);
  const { mutate, isPending } = useMutationCreateUserGroup();

  const handleSave = () => {
    mutate(
      {
        parameters: [
          { key: 'name', type: 'String', stringValue: name },
          {
            key: 'permissions',
            type: 'Collection',
            entityType: 'permission',
            collectionValues: values,
          },
        ],
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            message: t('message.success.changes-saved'),
            variant: 'success',
          });
          navigate(paths.main.usersGroups);
        },
      },
    );
  };

  return (
    <FormUsersGroup
      values={values}
      setValues={setValues}
      setName={setName}
      name={name}
      title={`${t('pages.users-groups.create-group')} `}
      handleSave={handleSave}
      loadingSave={isPending}
    />
  );
};
