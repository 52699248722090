import { Theme } from '@mui/material/styles';

export function Link(): Theme['components'] {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  };
}
