import { gql } from 'graphql-request';
import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import {
  ReindexRankingMutation,
  ReindexRankingMutationVariables,
} from './generated/reindexRanking';

export const useMutationReindexRanking = () => {
  const data = useMutation<
    ReindexRankingMutation,
    any,
    ReindexRankingMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(REINDEX_RANKING_TYPE, params),
  });
  return data;
};

export const REINDEX_RANKING_TYPE = gql`
  mutation ReindexRanking(
    $update: Boolean
    $recalculate: Boolean
    $reindex: Boolean
    $draft: Boolean
  ) {
    reindexRanking(
      update: $update
      recalculate: $recalculate
      reindex: $reindex
      draft: $draft
    )
  }
`;
