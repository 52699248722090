import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { gqlUsers } from '../gql';
import { GetPermissionsQuery } from '../gql/generated/getPermission';

interface Props {
  values: number[];
  setValues: Dispatch<SetStateAction<number[]>>;
  name?: string;
  setName?: Dispatch<SetStateAction<string>>;
  title: string;
  handleSave: () => void;
  handleRemove?: () => void;
  loadingSave: boolean;
  loadingRemove?: boolean;
}

export const FormUsersGroup = (props: Props) => {
  const { t } = useTranslation();
  const {
    title,
    setValues,
    values,
    handleSave,
    handleRemove,
    loadingRemove,
    loadingSave,
    setName,
    name,
  } = props;
  const { data } = useQuery({
    ...gqlUsers.getPermissions(),
  });
  const aggregateData = aggregateModules(data?.getPermissions?.items);

  const handleSelect = (value?: number) => {
    if (value) {
      setValues((prev) => {
        let copyPrev = [...prev];
        if (copyPrev.includes(value)) {
          copyPrev = copyPrev.filter((item) => item !== value);
        } else {
          copyPrev.push(value);
        }
        return copyPrev;
      });
    }
  };

  return (
    <Card sx={{ p: 4, m: 1 }} variant="outlined">
      <Typography variant="h4" gutterBottom mb={5}>
        {title}
      </Typography>
      {setName && (
        <TextField
          placeholder={t('pages.users-groups.name-groups')}
          value={name}
          onChange={(e) => setName(e?.target?.value || '')}
          sx={{ mb: 3, minWidth: 300 }}
        />
      )}
      <Grid
        gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
        display="grid"
        gap={3}
      >
        {Object.entries(aggregateData)?.map(([key, value]) => (
          <Box key={key}>
            <Typography fontSize={16}>{key?.replaceAll('_', ' ')}</Typography>
            <Box display="flex">
              {value?.map((child) => (
                <FormControlLabel
                  key={child?.id}
                  control={
                    <Checkbox
                      size="small"
                      checked={child?.id ? values?.includes(child?.id) : false}
                      onClick={() => handleSelect(child?.id)}
                    />
                  }
                  label={child?.action}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Grid>
      <Grid container item gap={2} justifyContent="flex-end">
        {handleRemove && (
          <LoadingButton
            variant="outlined"
            color="error"
            onClick={handleRemove}
            loading={loadingRemove}
          >
            {t('remove')}
          </LoadingButton>
        )}
        <LoadingButton
          variant="contained"
          onClick={handleSave}
          loading={loadingSave}
        >
          {t('save')}
        </LoadingButton>
      </Grid>
    </Card>
  );
};

type Items = GetPermissionsQuery['getPermissions']['items'];

const aggregateModules = (
  items: Items,
): { [key: string]: NonNullable<Items>[0][] } => {
  const moduleActions: { [key: string]: NonNullable<Items>[0][] } = {};

  items?.forEach((item) => {
    if (!moduleActions[item.module]) {
      moduleActions[item.module] = [];
    }
    moduleActions[item.module].push(item);
  });

  return moduleActions;
};
