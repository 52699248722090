import { ReactNode, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
  Theme,
} from '@mui/material';
import { MainCard } from 'components/MainCard';
import { Transitions } from 'components/@extended/Transitions';

import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { paths } from 'routes/paths';
import { useMutationLogout } from 'api/gql/mutations/logout';
import { queryStore } from 'api/gql/queries/queryStore';
import { useQuery } from '@tanstack/react-query';
import { EditAdminDialog } from 'pages/users/components/EditAdminDialog';
import { ProfileTab } from './ProfileTab';
import { Avatar } from './Avatar';

interface Props {
  children: ReactNode;
  value: number;
  index: number;
  dir: Theme['direction'];
}

export const TabPanel = ({ children, value, index, ...other }: Props) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`profile-tabpanel-${index}`}
    aria-labelledby={`profile-tab-${index}`}
    {...other}
  >
    {value === index && children}
  </div>
);

export const Profile = () => {
  const logout = useMutationLogout();
  const navigate = useNavigate();
  const theme = useTheme();
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const { data } = useQuery({
    ...queryStore.user.me(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleLogout = async () => {
    logout.mutate(undefined, {
      onSuccess: () => {
        navigate(paths.auth.login);
      },
    });
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'grey.300';

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <ButtonBase
          sx={{
            p: 0.25,
            bgcolor: open ? iconBackColorOpen : 'transparent',
            borderRadius: 1,
            '&:hover': { bgcolor: 'secondary.lighter' },
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? 'profile-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ p: 0.5 }}
          >
            <Avatar letter={data?.me?.firstName} />
          </Stack>
        </ButtonBase>
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 9],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              {open && (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: 290,
                    minWidth: 240,
                    maxWidth: 290,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250,
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <CardContent sx={{ px: 2.5, pt: 3 }}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Stack
                              direction="row"
                              spacing={1.25}
                              alignItems="center"
                            >
                              <Avatar letter={data?.me?.firstName} />
                              <Stack>
                                <Typography variant="h6">
                                  {data?.me?.firstName} {data?.me?.lastName}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="large"
                              color="secondary"
                              onClick={handleLogout}
                            >
                              <LogoutOutlined />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                      {open && (
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <ProfileTab
                            handleOpenEditDialog={() => {
                              setOpenEditUserDialog(true);
                            }}
                            handleLogout={handleLogout}
                          />
                        </Box>
                      )}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>
      <EditAdminDialog
        key={data?.me?.id}
        open={openEditUserDialog}
        id={data?.me?.id ?? 0}
        handleCloseDialog={() => setOpenEditUserDialog(false)}
        initialValues={{
          firstName: data?.me?.firstName || '',
          lastName: data?.me?.lastName || '',
          email: data?.me?.email || '',
          phone: data?.me?.phone || '',
          active: !!data?.me?.active,
          password: '',
        }}
        refetch={() => undefined}
      />
    </>
  );
};
