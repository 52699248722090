import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetUserGroupsQueryVariables,
  GetUserGroupsQuery,
} from './generated/userGroups';

export const getUsersGroups = (props: GetUserGroupsQueryVariables) =>
  gqlClient.request<GetUserGroupsQuery>(GET_USER_GROUPS, props);

export const GET_USER_GROUPS = gql`
  query getUserGroups(
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDir: String
    $phrase: String
  ) {
    getUserGroups(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        name
        permissions {
          id
          module
          action
          fullName
        }
      }
      count
    }
  }
`;
