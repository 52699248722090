import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  RankingTypeQuery,
  RankingTypeQueryVariables,
} from './generated/rankingType';

export const getRankingType = (props: RankingTypeQueryVariables) =>
  gqlClient.request<RankingTypeQuery>(RANKING_TYPE, props);

export const RANKING_TYPE = gql`
  query rankingType($id: Int!) {
    rankingType(id: $id) {
      configuration
      fields
      name
      relatedDraft {
        id
        name
        service
        configuration
      }
      configurationDiff
      draft
    }
  }
`;

export interface RankingWeight {
  type: string;
  label: string;
  attributes: RankingAttributes;
}

export interface RankingAttributes {
  max: number;
  min: number;
  step: string;
  required: boolean;
}
