import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, Grid, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { MainCard } from 'components/MainCard';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutationReindexRanking } from '../gql/reindexRanking';

interface Props {
  refetch: () => void;
}

export const ReindexRankingForm = ({ refetch }: Props) => {
  const { mutate, isPending } = useMutationReindexRanking();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    update: yup.boolean(),
    recalculate: yup.boolean(),
    reindex: yup.boolean(),
    draft: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      update: false,
      recalculate: false,
      reindex: false,
      draft: false,
    },
    validationSchema,
    onSubmit: (values) => {
      mutate(
        {
          ...values,
        },
        {
          onSuccess: () => {
            refetch();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <MainCard>
      <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
        <Grid container spacing={1} alignItems="center">
          <Grid container item spacing={2} xs={12} md={10}>
            <Grid item style={{ marginTop: '8px' }}>
              <Tooltip
                title="Update - powiązanie rezerwacji i wizyt na stronie z ofertami. Aktualizowane są dane dla dziś, wczoraj, -3 dni, -7 dni."
                placement="top-start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="update"
                      checked={formik.values.update}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Update"
                />
              </Tooltip>
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <Tooltip
                title="Recalculate - przeliczenie wszystkich rankingów cząstkowych i wyliczenie sumarycznych dla dziś, wczoraj, -3 dni, -7 dni."
                placement="top-start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="recalculate"
                      checked={formik.values.recalculate}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Recalculate"
                />
              </Tooltip>
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <Tooltip
                title="Reindex - reindeksacja w wyszukiwarce danych dla rankingów live i draft. Do indeksu wstawiane są tylko wartości sumaryczne."
                placement="top-start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reindex"
                      checked={formik.values.reindex}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Reindex"
                />
              </Tooltip>
            </Grid>
            <Grid item style={{ marginTop: '8px' }}>
              <Tooltip
                title="Draft - czy przeliczenie (recalculate) ma dotyczyć rankingu live czy draft."
                placement="top-start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="draft"
                      checked={formik.values.draft}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Draft"
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingButton
              disabled={!formik?.isValid}
              loading={isPending}
              variant="outlined"
              color="primary"
              type="submit"
              fullWidth
            >
              Reindexuj
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};
