import { EuroOutlined } from '@ant-design/icons';
import { TranslateOutlined } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';

const config = [
  {
    name: 'Miasta',
    url: 'dictionaries/cities',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Waluty',
    url: 'dictionaries/currencies',
    icon: <EuroOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Rodzaje łóżek',
    url: 'dictionaries/bed-types',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Rodzaje pokoi',
    url: 'dictionaries/room-types',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Oferta nie zawiera',
    url: 'dictionaries/exclude-types',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Oferta zawiera',
    url: 'dictionaries/include-types',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Województwa',
    url: 'dictionaries/provinces',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Pola warunków anulacji',
    url: 'dictionaries/cancellation-content',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
  {
    name: 'Kategorie',
    url: 'dictionaries/categories',
    icon: <TranslateOutlined style={{ fontSize: '36px' }} />,
  },
];

export const Dictionaries = () => (
  <div>
    <h1>Słowniki</h1>
    <Grid container spacing={1}>
      {config.map((item) => (
        <Grid item>
          <a href={item.url} style={{ textDecoration: 'none' }}>
            <Card>
              <Grid
                container
                sx={{ height: 140, width: 180 }}
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>{item.name}</Grid>
                <Grid item>{item.icon}</Grid>
              </Grid>
            </Card>
          </a>
        </Grid>
      ))}
    </Grid>
  </div>
);
