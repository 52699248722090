import { Box } from '@mui/material';

export const getRandomColor = () => {
  const colors = [
    '#FF5733',
    '#3357FF',
    '#8E44AD',
    '#EC7063',
    '#5DADE2',
    '#48C9B0',
    '#F39C12',
    '#7D3C98',
    '#2ECC71',
  ];

  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

const COLOR = getRandomColor();

export const Avatar = ({ letter = '' }) => (
  <Box
    sx={{
      borderRadius: '50%',
      background: COLOR,
      width: 32,
      height: 32,
      color: 'white',
      fontWeight: '600',
      fontSize: '18px',
    }}
    alignItems="center"
    justifyContent="center"
    display="flex"
  >
    {letter?.[0]?.toUpperCase()}
  </Box>
);
