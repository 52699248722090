export const paths = {
  base: '/',
  main: {
    dashboard: '/',
    hotels: '/hotels',
    hotelsOffers: '/hotels/offers',
    hotelsOfferDetails: '/hotels/offers/:offerId',
    ranking: '/ranking',
    loyalty: '/loyalty',
    usersManagement: '/users-management/users',
    users: '/users-management/users',
    usersAdmins: '/users-management/admins',
    usersSubscribers: '/users-management/subscribers',
    usersGroups: '/users-management/users-groups',
    addUserGroup: '/users-management/users-groups/add-group',
    updateUserGroup: '/users-management/users-groups/:id',
    dictionaries: '/dictionaries',
    cities: '/dictionaries/cities',
    currencies: '/dictionaries/currencies',
    bedTypes: '/dictionaries/bed-types',
    roomTypes: '/dictionaries/room-types',
    excludeTypes: '/dictionaries/exclude-types',
    includeTypes: '/dictionaries/include-types',
    provinces: '/dictionaries/provinces',
    categories: '/dictionaries/categories',
    cancellationContent: '/dictionaries/cancellation-content',
    maintenance: '/maintenance/webhook-logs',
    webhookLogs: '/maintenance/webhook-logs',
    rankingLogs: '/maintenance/ranking-logs',
    notAllowed: '/not-allowed',
  },
  outside: {},
  auth: {
    login: '/login',
    register: '/register',
  },
  all: '*',
} as const;
