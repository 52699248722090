import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useRowModesModel } from 'components/DataGrid/hooks/useRowModesModel';
import { sxDisablePagination } from 'components/DataGrid/utils/sxDisablePagination';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { gqlSegments } from '../gql';
import { SegmentsQuery } from '../gql/generated/segments';
import { useMutationUpdateSegment } from '../gql/updateSegment';
import { Level } from './Level';

type Row = GridRowModel<NonNullable<SegmentsQuery['segments']>[0]>;

export const TableTricoins = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const updateSegment = useMutationUpdateSegment();
  const { data, isLoading } = useQuery({ ...gqlSegments.segments });
  const {
    handleRowEditStop,
    handleRowModesModelChange,
    rowModesModel,
    getActions,
  } = useRowModesModel();

  const processRowUpdate = useCallback(
    (newRow: Row, oldRow: Row) => {
      const {
        id,
        allocationRate,
        coinsForBirthday,
        coinsForJoining,
        coinsForPreferences,
        coinsForReferral,
        coinsForReviewWithPhoto,
        coinsForReviewWithoutPhoto,
        coinsForSubscription,
        coinsForSurvey,
      } = newRow;

      if (id && !isEqual(newRow, oldRow)) {
        updateSegment.mutate(
          {
            id,
            parameters: [
              {
                key: 'allocationRate',
                type: 'Float',
                floatValue: allocationRate,
              },
              {
                key: 'coinsForSubscription',
                type: 'Float',
                floatValue: coinsForSubscription,
              },
              {
                key: 'coinsForPreferences',
                type: 'Float',
                floatValue: coinsForPreferences,
              },
              {
                key: 'coinsForReviewWithoutPhoto',
                type: 'Float',
                floatValue: coinsForReviewWithoutPhoto,
              },
              {
                key: 'coinsForReviewWithPhoto',
                type: 'Float',
                floatValue: coinsForReviewWithPhoto,
              },
              {
                key: 'coinsForBirthday',
                type: 'Float',
                floatValue: coinsForBirthday,
              },
              {
                key: 'coinsForReferral',
                type: 'Float',
                floatValue: coinsForReferral,
              },
              {
                key: 'coinsForJoining',
                type: 'Float',
                floatValue: coinsForJoining,
              },
              {
                key: 'coinsForSurvey',
                type: 'Float',
                floatValue: coinsForSurvey,
              },
            ],
          },
          {
            onSuccess: () => {
              enqueueSnackbar({
                message: t('message.success.changes-saved'),
                variant: 'success',
              });
            },
            onError: () => {
              enqueueSnackbar({
                message: t('message.error.error-occured'),
                variant: 'error',
              });
            },
          },
        );
        return newRow;
      }

      return oldRow;
    },
    [enqueueSnackbar, t, updateSegment],
  );

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    renderHeader: (params) => (
      <HeaderRow
        dangerouslySetInnerHTML={{ __html: params.colDef.headerName || '' }}
      />
    ),
    editable: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'symbol',
      headerName: t('pages.segments.level'),
      align: 'center',
      headerAlign: 'center',
      width: 70,
      ...defaultProps,
      editable: false,
      renderCell: (params) => <Level type={params.value}>{params.value}</Level>,
    },
    {
      field: 'allocationRate',
      type: 'number',
      headerName: t('pages.segments.tricoins.100'),
      ...defaultProps,
    },
    {
      field: 'coinsForSubscription',
      type: 'number',
      headerName: t('pages.segments.tricoins.sub'),
      ...defaultProps,
    },
    {
      field: 'coinsForPreferences',
      type: 'number',
      headerName: t('pages.segments.tricoins.pref'),
      ...defaultProps,
    },
    {
      field: 'coinsForReviewWithoutPhoto',
      type: 'number',
      headerName: t('pages.segments.tricoins.without-photo'),
      ...defaultProps,
    },
    {
      field: 'coinsForReviewWithPhoto',
      type: 'number',
      headerName: t('pages.segments.tricoins.with-photo'),
      ...defaultProps,
    },
    {
      field: 'coinsForBirthday',
      type: 'number',
      headerName: t('pages.segments.tricoins.birthday'),
      ...defaultProps,
    },
    {
      field: 'coinsForReferral',
      type: 'number',
      headerName: t('pages.segments.tricoins.recommend'),
      ...defaultProps,
    },
    {
      field: 'coinsForJoining',
      type: 'number',
      headerName: t('pages.segments.tricoins.join-club'),
      ...defaultProps,
    },
    {
      field: 'coinsForSurvey',
      type: 'number',
      headerName: t('pages.segments.tricoins.form'),
      ...defaultProps,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      headerName: '',
      getActions,
      flex: 1,
    },
  ];

  return (
    <DataGrid
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnSorting
      columns={columns}
      rows={data?.segments || []}
      loading={isLoading}
      autoHeight
      editMode="row"
      processRowUpdate={processRowUpdate}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      sx={sxDisablePagination}
      columnHeaderHeight={120}
    />
  );
};

export const HeaderRow = styled.div`
  white-space: normal;
  text-align: center;
  font-weight: 600;
  height: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    font-weight: 400;
  }
`;
