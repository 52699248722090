import { Theme } from '@mui/material';
import { GridLocaleText } from '@mui/x-data-grid';
import { plPL, enUS } from '@mui/x-data-grid/locales';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export function DataGrid(theme: Theme) {
  const { t } = useTranslation();
  const activeLang = i18next.language;

  const getLang = () => {
    switch (activeLang) {
      case 'pl':
        return plPL;
      case 'en':
        return enUS;
      default:
        return plPL;
    }
  };

  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '--unstable_DataGrid-headWeight': 600,
          '--DataGrid-containerBackground': 'theme.palette.background.paper',
          '--DataGrid-rowBorderColor': theme.palette.divider,
          borderColor: theme.palette.divider,
          fontSize: '0.875rem',
          background: theme.palette.background.paper,
        },
        columnSeparator: {
          color: theme.palette.grey[300],
        },
        iconButtonContainer: {
          marginLeft: 8,
        },
      },
      defaultProps: {
        localeText: {
          ...getLang()?.components.MuiDataGrid.defaultProps.localeText,
          columnsManagementSearchTitle: t('search'),
          columnsManagementShowHideAllText: t(
            'columnsManagementShowHideAllText',
          ),
        } as Partial<GridLocaleText>,
      },
    },
  };
}
