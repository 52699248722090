import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import { MeQuery } from './generated/gql';

export const getMe = () => gqlClient.request<MeQuery>(ME);

export const ME = gql`
  query me {
    me {
      email
      phone
      firstName
      lastName
      identifier
      removed
      roles
      id
      createdAt
      updatedAt
      token
      active
    }
  }
`;
