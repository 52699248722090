import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateAdminMutation,
  CreateAdminMutationVariables,
} from './generated/createAdminUser';

export const useMutationCreateAdminUser = () => {
  const data = useMutation<
    CreateAdminMutation,
    any,
    CreateAdminMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(CREATE_ADMIN_USER_MUTATION, params),
  });
  return data;
};

export const CREATE_ADMIN_USER_MUTATION = gql`
  mutation CreateAdmin($input: UserInput!) {
    createAdminUser(input: $input) {
      id
    }
  }
`;
