import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateCancellationContentMutation,
  UpdateCancellationContentMutationVariables,
} from './generated/updateCancellationContent';

export const useMutationUpdateCancellationContent = () => {
  const data = useMutation<
    UpdateCancellationContentMutation,
    any,
    UpdateCancellationContentMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(UPDATE_CANCELLATION_CONTENT_MUTATION, params),
  });
  return data;
};

export const UPDATE_CANCELLATION_CONTENT_MUTATION = gql`
  mutation updateCancellationContent(
    $id: Int!
    $parameters: [ConfigurationInput!]!
  ) {
    updateCancellationContent(id: $id, parameters: $parameters) {
      id
      migrationId
      pl: translate(locale: "pl") {
        title
        content
      }
      en: translate(locale: "en") {
        title
        content
      }
    }
  }
`;
