import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  AddFundsMutation,
  AddFundsMutationVariables,
} from './generated/addFunds';

export const useMutationAddFunds = () => {
  const data = useMutation<AddFundsMutation, any, AddFundsMutationVariables>({
    mutationFn: (params) => gqlClient.request(ADD_FUNDS_MUTATION, params),
  });
  return data;
};

export const ADD_FUNDS_MUTATION = gql`
  mutation addFunds($id: Int!, $coins: Float!, $comment: String!) {
    addFunds(
      input: { userId: $id, event: "manual", coins: $coins, comment: $comment }
    )
  }
`;
