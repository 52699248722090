import { Button, Grid, IconButton } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridSortModel,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { t } from 'i18next';
import { ChangeEvent, useState } from 'react';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { useNavigate } from 'react-router';
import { paths } from 'routes/paths';
import { EditOutlined } from '@ant-design/icons';
import { DefaultGridToolbar } from 'components/DataGrid';
import { gqlUsers } from './gql';

export const UsersGroups = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const [sortBy, setSortBy] = useQueryParamsState('sortBy', 'id');
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'desc');
  const [searchPhrase, setSearchPhrase] = useState('');

  const { data, isLoading, isFetching } = useQuery({
    ...gqlUsers.getUsersGroups({
      offset: offset * limit,
      limit,
      orderBy: sortBy,
      orderDir: sortDir,
      phrase: searchPhrase,
    }),
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate(paths.main.updateUserGroup.replace(':id', `${params?.id}`));
  };

  const handleSearchInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.preventDefault();
    setSearchPhrase(event.target.value);
  };

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    if (!item) {
      setSortBy('id');
      setSortDir('desc');
      return;
    }
    setSortBy(item.field);
    setSortDir(item.sort || 'desc');
  };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('id'),
      ...defaultProps,
      minWidth: 90,
      maxWidth: 90,
      resizable: false,
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('pages.users-groups.name-groups'),
      ...defaultProps,
    },
    {
      field: 'edit',
      headerName: t('pages.users.edit'),
      ...defaultProps,
      maxWidth: 100,
      renderCell: () => (
        <IconButton>
          <EditOutlined />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <h2>{t('paths.users-groups')}</h2>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <SearchBar
            placeholder={t('search')}
            value={searchPhrase}
            handleSearchInputChange={handleSearchInputChange}
          />
          <Button
            variant="contained"
            onClick={() => navigate(paths.main.addUserGroup)}
          >
            {t('pages.users-groups.create-group')}
          </Button>
        </Grid>
      </Grid>
      <Grid item width="100%">
        <DataGrid
          loading={isLoading || isFetching}
          disableRowSelectionOnClick
          rows={data?.getUserGroups?.items || []}
          columns={columns}
          autoHeight
          pageSizeOptions={[10, 25, 50, 75]}
          paginationMode="server"
          paginationModel={{ page: offset, pageSize: limit }}
          onPaginationModelChange={handlePagination}
          rowCount={data?.getUserGroups.count || 0}
          sortingMode="server"
          onSortModelChange={onSortModelChange}
          onRowClick={handleRowClick}
          disableColumnMenu
          slots={{
            toolbar: DefaultGridToolbar,
          }}
        />
      </Grid>
    </Grid>
  );
};
