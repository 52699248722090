import { css } from '@emotion/react';
import styled from '@emotion/styled';

export type LoyaltyLevel = 'bronze' | 'silver' | 'gold' | 'vip';

export const getLevelColor = (type: LoyaltyLevel) => {
  switch (type) {
    case 'bronze':
      return css`
        background: linear-gradient(66.38deg, #a14d0c 12.37%, #e08c3b 99.54%);
      `;
    case 'silver':
      return css`
        background: linear-gradient(66.38deg, #8a8c91 12.37%, #dfdfdf 99.54%);
      `;
    case 'gold':
      return css`
        background: linear-gradient(66.38deg, #e0af00 12.37%, #ffe071 99.54%);
      `;
    case 'vip':
      return css`
        background: linear-gradient(74.28deg, #e84e07 0%, #ff7d18 100%);
      `;
    default:
      return css`
        background: linear-gradient(66.38deg, #a14d0c 12.37%, #e08c3b 99.54%);
      `;
  }
};

export const Level = styled.span<{ type: LoyaltyLevel }>`
  ${({ type }) => css`
    ${getLevelColor(type)};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: capitalize;
    font-weight: 600;
    width: fit-content;
    ${type === 'vip' &&
    css`
      text-transform: uppercase;
    `}
  `};
`;
