import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { Profile } from './Profile';
import { Notification } from './Notification';
import { LangChanger } from '../../components/LangChanger';

export const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      width="100%"
    >
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      <LangChanger />
      <Notification />
      <Profile />
    </Stack>
  );
};
