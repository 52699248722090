import { useState, MouseEvent, CSSProperties } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Box, Menu, Stack } from '@mui/material';
import 'react-day-picker/style.css';

export const DateRangePicker = ({
  onChange,
  selectedDate,
}: {
  onChange: (date: DateRange | undefined) => void;
  selectedDate: DateRange | undefined;
}) => {
  const handleDayPickerSelect = (date: DateRange | undefined) => {
    onChange(date);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" spacing={2}>
      <Stack onClick={handleClick} direction="row" spacing={2}>
        <DatePicker
          label="Start date"
          readOnly
          value={selectedDate?.from ? dayjs(selectedDate?.from) : undefined}
        />
        <DatePicker
          label="End date"
          readOnly
          value={selectedDate?.to ? dayjs(selectedDate?.to) : undefined}
        />
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box p={2}>
          <DayPicker
            style={
              {
                '--rdp-accent-color': '#1677ff',
              } as CSSProperties
            }
            mode="range"
            selected={selectedDate}
            onSelect={handleDayPickerSelect}
          />
        </Box>
      </Menu>
    </Stack>
  );
};
