import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from './generated/updateUser';

export const useMutationUpdateUser = () => {
  const data = useMutation<
    UpdateUserMutation,
    any,
    UpdateUserMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_USER_MUTATION, params),
  });
  return data;
};

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateUser(id: $id, parameters: $parameters) {
      id
      segment {
        symbol
      }
      firstName
      lastName
      email
      phone
      loyaltyCardNumber
    }
  }
`;
