import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import { SegmentsQuery } from './generated/segments';

export const getSegments = () => gqlClient.request<SegmentsQuery>(SEGMENTS);

export const SEGMENTS = gql`
  query segments {
    segments {
      id
      reservations
      # days
      enabled
      symbol
      allocationRate
      coinsForSubscription
      coinsForPreferences
      coinsForReviewWithoutPhoto
      coinsForReviewWithPhoto
      coinsForBirthday
      coinsForReferral
      coinsForJoining
      coinsForSurvey
    }
  }
`;
