/* eslint-disable no-confusing-arrow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { useState, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import { DateRangePicker } from 'components/DateRangePicker';
import { DateRange } from 'react-day-picker';
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from '@mui/x-data-grid';
import { Check, Clear } from '@mui/icons-material';
import { gqlHotels } from './gql';
import { EditHotelOfferDetailsDialog } from './EditHotelOfferDetailsDialog';

const emptyDetail = {
  id: 0,
  name: '',
  startDate: '',
  endDate: '',
  price: 0,
  pricePromo: 0,
  adBedAdultPrice: 0,
  adBedChildPrice: 0,
  quantity: 0,
  minLos: 0,
  isWeekend: false,
};

type HotelDetailRow = {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  price: number;
  pricePromo: number;
  adBedAdultPrice: number;
  adBedChildPrice: number;
  quantity: number;
  minLos: number;
  isWeekend: boolean;
};

const defaultFromDate = dayjs().toDate();
const defaultToDate = dayjs(defaultFromDate).add(7, 'day').toDate();

export const HotelOfferDetails = () => {
  const params = useParams();
  const offerId = Number(params.offerId);

  const [groupSimilar, setGroupSimilar] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const [detailToEdit, setDetailToEdit] = useState<HotelDetailRow>(emptyDetail);
  const [selectedDate, setSelectedDate] = useState<DateRange | undefined>({
    from: defaultFromDate,
    to: defaultToDate,
  });

  const handleCloseDialog = () => {
    setDetailToEdit(emptyDetail);
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const setDetail = (row: HotelDetailRow) => {
    setDetailToEdit(row);
    handleOpenDialog();
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    ...gqlHotels.getOfferCalendarStays({
      id: offerId,
      groupSimilar,
      startDate: selectedDate?.from
        ? dayjs(selectedDate.from).format('YYYY-MM-DD')
        : undefined,
      endDate: selectedDate?.to
        ? dayjs(selectedDate.to).format('YYYY-MM-DD')
        : undefined,
    }),
  });

  const handleDayPickerSelect = (date: DateRange | undefined) => {
    setSelectedDate(date);
  };

  const rows = useMemo(
    () =>
      data?.getOfferCalendarStays?.map((row) => ({
        id: row.room.id,
        name: row.room.translate.name,
        startDate: row.startDate,
        endDate: row.endDate,
        price: row.price,
        pricePromo: row.pricePromo,
        adBedAdultPrice: row.adBedAdultPrice,
        adBedChildPrice: row.adBedChildPrice,
        quantity: row.quantity,
        minLos: row.minLos,
        maxLos: row.maxLos,
        noArrival: row.noArrival,
        noDeparture: row.noDeparture,
        isWeekend:
          new Date(row.startDate).getDay() % 6 === 0 ||
          new Date(row.endDate).getDay() % 6 === 0,
      })) || [],
    [data],
  );

  const columns = useMemo<Array<ColumnDef<any>>>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        id: 'name',
      },
      {
        accessorKey: 'startDate',
        header: 'Data od',
        maxSize: 100,
      },
      {
        accessorKey: 'endDate',
        header: 'Data do',
        maxSize: 100,
      },
      {
        accessorKey: 'price',
        header: 'Cena hotel',
        maxSize: 80,
      },
      {
        accessorKey: 'pricePromo',
        header: 'Cena Triverna',
        maxSize: 80,
      },
      {
        accessorKey: 'adBedAdultPrice',
        header: 'Dostawka dorosły',
        maxSize: 80,
      },
      {
        accessorKey: 'adBedChildPrice',
        header: 'Dostawka dziecko',
        maxSize: 80,
      },
      {
        accessorKey: 'quantity',
        header: 'Dostępność',
        id: 'quantity',
        maxSize: 80,
      },
      {
        accessorKey: 'minLos',
        header: 'MinLos',
        maxSize: 80,
      },
      {
        accessorKey: 'maxLos',
        header: 'MaxLos',
        maxSize: 80,
      },
      {
        accessorKey: 'noArrival',
        header: 'noArrival',
        maxSize: 80,
        cell: ({ row }) =>
          row.original.noArrival ? (
            <Check color="success" />
          ) : (
            <Clear color="error" />
          ),
      },
      {
        accessorKey: 'noDeparture',
        header: 'noDeparture',
        maxSize: 80,
        cell: ({ row }) =>
          row.original.noDeparture ? (
            <Check color="success" />
          ) : (
            <Clear color="error" />
          ),
      },
      {
        id: 'edit',
        accessorKey: 'edit',
        header: '',
        maxSize: 50,
        enableResizing: true,
        meta: { grow: true } as any,
        cell: ({ row }) => (
          <IconButton onClick={() => setDetail(row.original)}>
            <EditOutlined />
          </IconButton>
        ),
      },
    ],
    [],
  );

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid
          mt={3}
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <DateRangePicker
            selectedDate={selectedDate}
            onChange={handleDayPickerSelect}
          />
        </Grid>

        <Grid
          mt={1}
          container
          direction="row"
          justifyContent="end"
          item
          style={{ width: '100%' }}
        >
          <FormControlLabel
            value="start"
            control={
              <Switch
                checked={groupSimilar}
                onChange={(_, checked) => setGroupSimilar(checked)}
                color="primary"
              />
            }
            label="Połącz te same"
            labelPlacement="start"
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {isLoading || isFetching ? (
            <Box
              sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <DetailsTable
              groupSimilar={groupSimilar}
              columns={columns}
              data={rows}
            />
          )}
        </Grid>
      </Grid>
      <EditHotelOfferDetailsDialog
        open={openDialog}
        offerId={offerId}
        handleCloseDialog={handleCloseDialog}
        initialValues={detailToEdit}
        refetch={refetch}
      />
    </div>
  );
};

const DetailsTable = ({ data, columns, groupSimilar }: any) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: { columnVisibility: { quantity: !groupSimilar } },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const { rows } = table.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 71,
    overscan: 20,
  });

  return (
    <TableContainer component={Paper}>
      <div style={{ maxWidth: '100%', width: '100%' }}>
        <div
          ref={parentRef}
          style={{
            overflowY: 'auto',
            position: 'relative',
            maxHeight: '800px',
          }}
        >
          <Table style={{ display: 'grid', width: '100%' }}>
            <TableHead
              style={{
                display: 'grid',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#fff',
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  style={{ display: 'flex', width: '100%' }}
                >
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      style={{
                        display: 'flex',
                        width: header.getSize(),
                        fontSize: '12px',
                        flexGrow: ['edit', 'name'].includes(
                          `${header.column.columnDef.id}`,
                        )
                          ? 1
                          : 0,
                      }}
                    >
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: (
                            <>
                              {' '}
                              <GridArrowUpwardIcon
                                style={{ fontSize: '10px' }}
                              />
                            </>
                          ),
                          desc: (
                            <>
                              {' '}
                              <GridArrowDownwardIcon
                                style={{ fontSize: '10px' }}
                              />
                            </>
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody
              style={{
                display: 'grid',
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index];
                const { isWeekend } = row.original as HotelDetailRow;
                return (
                  <TableRow
                    data-index={virtualRow.index}
                    ref={(node) => rowVirtualizer.measureElement(node)}
                    key={row.id}
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      transform: `translateY(${virtualRow.start}px)`,
                      width: '100%',
                      background: isWeekend ? '#eee' : '',
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        style={{
                          display: 'flex',
                          width: cell.column.getSize(),
                          flexGrow: ['edit', 'name'].includes(
                            `${cell.column.columnDef.id}`,
                          )
                            ? 1
                            : 0,
                          justifyContent:
                            cell.column.columnDef.id === 'edit'
                              ? 'flex-end'
                              : 'flex-start',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </TableContainer>
  );
};
