import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateContactMutation,
  UpdateContactMutationVariables,
} from './generated/updateContact';

export const useMutationUpdateContact = () => {
  const data = useMutation<
    UpdateContactMutation,
    any,
    UpdateContactMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_CONTACT_MUTATION, params),
  });
  return data;
};

export const UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateContact(id: $id, parameters: $parameters) {
      id
      firstName
      lastName
      email
    }
  }
`;
