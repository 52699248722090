import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetBedTypesQueryVariables,
  GetBedTypesQuery,
} from './generated/getBedTypes';

export const getBedTypes = (props: GetBedTypesQueryVariables) =>
  gqlClient.request<GetBedTypesQuery>(GET_BED_TYPES, props);

export const GET_BED_TYPES = gql`
  query getBedTypes(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getBedTypes(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        migrationId
        pl: translate(locale: "pl") {
          name
        }
        en: translate(locale: "en") {
          name
        }
      }
      count
    }
  }
`;
