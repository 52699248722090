import { Roles } from 'types';
import { useQuery } from '@tanstack/react-query';
import { queryStore } from 'api/gql/queries/queryStore';

export const useRole = (): Roles => {
  const { data } = useQuery({
    ...queryStore.user.isLogged(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { isAdminLoggedUser, isLoggedUser } = data || {};

  if (isAdminLoggedUser) {
    return 'ADMIN';
  }

  if (isLoggedUser) {
    return 'CLIENT';
  }

  return undefined;
};
