import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetWebhookLogsQuery,
  GetWebhookLogsQueryVariables,
} from './generated/getWebhookLogs';

export const getWebhookLogs = (props: GetWebhookLogsQueryVariables) =>
  gqlClient.request<GetWebhookLogsQuery>(GET_WEBHOOK_LOGS, props);

export const GET_WEBHOOK_LOGS = gql`
  query getWebhookLogs(
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDir: String
    $conditions: [ConditionInput]
  ) {
    getWebhookLogs(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
      conditions: $conditions
    ) {
      items {
        id
        migrationId
        type
        createdAt
      }
      count
    }
  }
`;
