import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateCurrencyMutation,
  CreateCurrencyMutationVariables,
} from './generated/createCurrency';

export const useMutationCreateCurrency = () => {
  const data = useMutation<
    CreateCurrencyMutation,
    any,
    CreateCurrencyMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(CREATE_CURRENCY_MUTATION, params),
  });
  return data;
};

export const CREATE_CURRENCY_MUTATION = gql`
  mutation createCurrency($parameters: [ConfigurationInput!]!) {
    createCurrency(parameters: $parameters) {
      id
      migrationId
      name
      symbol
    }
  }
`;
