import { useState } from 'react';

import { Box, Divider, List, Typography } from '@mui/material';
import { NavItemProps } from 'types/menu';
import { menuItems } from 'layout/MainLayout/components/menu-items';
import { NavItem } from './NavItem';
import { NavGroup } from './NavGroup';
import { getName } from './utils';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

export const Navigation = () => {
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);

  const lastItem = 7;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems: NavItemProps[] = [];
  let lastItemId: string;

  //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
  // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items
      .slice(lastItem - 1, menuItems.items.length)
      .map((item) => ({
        title: getName(item.name),
        elements: item.children,
        icon: item.icon,
        ...(item.url && {
          url: item.url,
        }),
      }));
  }

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        if (item.url && item.id !== lastItemId) {
          return (
            <List key={item.id}>
              <Divider sx={{ my: 0.5 }} />
              <NavItem item={item} level={1} isParents />
            </List>
          );
        }

        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        '& > ul:first-of-type': { mt: 0 },
        display: 'block',
      }}
    >
      {navGroups}
    </Box>
  );
};
