/* eslint-disable no-plusplus */

export const months = [
  { value: 1, label: 'Styczeń' },
  { value: 2, label: 'Luty' },
  { value: 3, label: 'Marzec' },
  { value: 4, label: 'Kwiecień' },
  { value: 5, label: 'Maj' },
  { value: 6, label: 'Czerwiec' },
  { value: 7, label: 'Lipiec' },
  { value: 8, label: 'Sierpień' },
  { value: 9, label: 'Wrzesień' },
  { value: 10, label: 'Październik' },
  { value: 11, label: 'Listopad' },
  { value: 12, label: 'Grudzień' },
];

const getYears = (
  startYear: number,
  endYear: number,
): { value: number; label: number }[] => {
  const yearsArray = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push({ value: year, label: year });
  }
  return yearsArray;
};

export const years = getYears(2010, 2050);
