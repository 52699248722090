import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import { OffersQuery, OffersQueryVariables } from './generated/offers';

export const getOffers = (props: OffersQueryVariables) =>
  gqlClient.request<OffersQuery>(OFFERS, props);

export const OFFERS = gql`
  query offers($criteria: CriteriaInput, $conditions: [ConditionInput]) {
    offers(criteria: $criteria, conditions: $conditions) {
      count
      items {
        id
        translate {
          name
        }
        hotel {
          translate {
            name
          }
        }
        ranking
        rankingDraft
        # GPV skorygowane
        adjustedProvisionViewsRanking
        adjustedProvisionViewsRanking0
        adjustedProvisionViewsRanking1
        adjustedProvisionViewsRanking3
        adjustedProvisionViewsRanking7
        # GPV
        provisionViewsRanking
        provisionViewsRanking0
        provisionViewsRanking1
        provisionViewsRanking3
        provisionViewsRanking7
        # GP
        provisionRanking
        provisionRanking0
        provisionRanking1
        provisionRanking3
        provisionRanking7
        # Zmiana dostępności
        availabilityChangeRanking
        availabilityChangeRanking0
        availabilityChangeRanking1
        availabilityChangeRanking3
        availabilityChangeRanking7
        # Zmiana ceny
        priceChangeRanking
        priceChangeRanking0
        priceChangeRanking1
        priceChangeRanking3
        priceChangeRanking7
        # Liczba rezerwacji
        reservationsRanking
        reservationsRanking0
        reservationsRanking1
        reservationsRanking3
        reservationsRanking7
        # Liczba wyświetleń
        viewsRanking
        viewsRanking0
        viewsRanking1
        viewsRanking3
        viewsRanking7
        # GPV skorygowane
        adjustedProvisionViewsDraftRanking # łącznie
        adjustedProvisionViewsDraftRanking0 # dzisiaj
        adjustedProvisionViewsDraftRanking1 # wczoraj
        adjustedProvisionViewsDraftRanking3 # -3 dni
        adjustedProvisionViewsDraftRanking7 # -7 dni
        # GPV
        provisionViewsDraftRanking # łącznie
        provisionViewsDraftRanking0 # dzisiaj
        provisionViewsDraftRanking1 # wczoraj
        provisionViewsDraftRanking3 # -3 dni
        provisionViewsDraftRanking7 # -7 dni
        # GP
        provisionDraftRanking # łącznie
        provisionDraftRanking0 # dzisiaj
        provisionDraftRanking1 # wczoraj
        provisionDraftRanking3 # -3 dni
        provisionDraftRanking7 # -7 dni
        # Zmiana dostępności
        availabilityChangeDraftRanking # łącznie
        availabilityChangeDraftRanking0 # dzisiaj
        availabilityChangeDraftRanking1 # wczoraj
        availabilityChangeDraftRanking3 # -3 dni
        availabilityChangeDraftRanking7 # -7 dni
        # Zmiana ceny
        priceChangeDraftRanking # łącznie
        priceChangeDraftRanking0 # dzisiaj
        priceChangeDraftRanking1 # wczoraj
        priceChangeDraftRanking3 # -3 dni
        priceChangeDraftRanking7 # -7 dni
        # Liczba rezerwacji
        reservationsDraftRanking # łącznie
        reservationsDraftRanking0 # dzisiaj
        reservationsDraftRanking1 # wczoraj
        reservationsDraftRanking3 # -3 dni
        reservationsDraftRanking7 # -7 dni
        # Liczba wyświetleń
        viewsDraftRanking # łącznie
        viewsDraftRanking0 # dzisiaj
        viewsDraftRanking1 # wczoraj
        viewsDraftRanking3 # -3 dni
        viewsDraftRanking7 # -7 dni
      }
    }
  }
`;
