import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutationCreateProvince } from '../gql/createProvince';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  refetch: () => void;
}

export const CreateProvinceDialog = ({
  open,
  handleCloseDialog,
  refetch,
}: Props) => {
  const { mutate } = useMutationCreateProvince();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    name: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (value) => {
      mutate(
        {
          parameters: [
            { key: 'name', type: 'String', stringValue: value.name },
          ],
        },
        {
          onSuccess: () => {
            refetch();
            handleCloseDialog();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Dodaj Województwo</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid container spacing={1}>
            <Grid container item spacing={1} direction="column">
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="Nazwa"
                  name="name"
                  id="name"
                  value={formik.values.name || ''}
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  disabled={!formik?.isValid}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
