import { Theme } from '@mui/material';

export function Typography(): Theme['components'] {
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12,
        },
      },
    },
  };
}
