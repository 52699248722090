import { gql } from 'graphql-request';
import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import {
  PublishDraftMutation,
  PublishDraftMutationVariables,
} from './generated/publishRankingTypeDraft';

export const useMutationPublishDraftMutation = () => {
  const data = useMutation<
    PublishDraftMutation,
    any,
    PublishDraftMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(PUBLISH_RANKING_TYPE_DRAFT_MUTATION, params),
  });
  return data;
};

export const PUBLISH_RANKING_TYPE_DRAFT_MUTATION = gql`
  mutation publishDraft($id: Int!) {
    publishRankingTypeDraft(id: $id) {
      id
    }
  }
`;
