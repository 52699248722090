import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateBedTypeMutationVariables,
  CreateBedTypeMutation,
} from './generated/createBedType';

export const useMutationCreateBedType = () => {
  const data = useMutation<
    CreateBedTypeMutation,
    any,
    CreateBedTypeMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(CREATE_BED_TYPE_MUTATION, params),
  });
  return data;
};

export const CREATE_BED_TYPE_MUTATION = gql`
  mutation createBedType($parameters: [ConfigurationInput!]!) {
    createBedType(parameters: $parameters) {
      id
      migrationId
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
