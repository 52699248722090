import { Link } from 'react-router-dom';
import { ButtonBase, SxProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { config } from 'config';
import { activeItem } from 'store/reducers/menu';
import { Store } from 'store/reducers';
import { Logo } from './Logo';

interface Props {
  sx?: SxProps;
  to?: string;
}

export const LogoSection = ({ sx, to }: Props) => {
  const { defaultId } = useSelector((state: Store) => state.menu);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
      to={!to ? config.defaultPath : to}
      sx={sx}
    >
      <Logo />
    </ButtonBase>
  );
};
