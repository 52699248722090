import {
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Typography,
  Select,
} from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useRef } from 'react';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { useTranslation } from 'react-i18next';
import { EditRanking } from './components/EditRanking';
import { GridRanking } from './components/GridRanking';
import { ReindexRankingForm } from './components/ReindexRankingForm';
import { gqlRanking } from './gql';
import { Services } from './gql/rankingTypes';

export const Ranking = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [draft, setDraft] = useQueryParamsState('draft', false);
  const [ranking, setRanking] = useQueryParamsState('id', 9);
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 50);
  const [orderBy, setOrderBy] = useQueryParamsState<string | undefined>(
    'orderBy',
    '',
  );
  const [orderDir, setOrderDir] = useQueryParamsState<string | undefined>(
    'orderDir',
    '',
  );
  const [active, setActive] = useQueryParamsState('active', '-1');

  const queryRankingTypes = useQuery({
    ...gqlRanking.rankingTypes({ draft: false }),
  });
  const options = useMemo(
    () => queryRankingTypes?.data?.rankingTypes || [],
    [queryRankingTypes],
  );
  const activeRanking =
    options?.filter((item) => item?.id === ranking)?.[0] || {};

  const { data, isLoading, refetch } = useQuery({
    ...gqlRanking.offers({
      criteria: {
        offset: offset * limit,
        limit,
        orderBy: orderBy || undefined,
        orderDir: orderDir || undefined,
      },
      conditions: [
        {
          column: 'offer.active',
          value: active,
          operator: active === '-1' ? 'gte' : 'eq',
          type: 'AND',
        },
      ],
    }),
  });

  const rows =
    data?.offers?.items?.map((item) => ({
      ...item,
      name: item?.translate?.name,
      nameHotel: item?.hotel?.translate?.name,
    })) || [];

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);

    if (ref?.current) {
      ref?.current?.scrollIntoView();
      window.scrollBy(0, -64);
    }
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    const { field, sort } = item || {};
    const getOrderBy = () => {
      switch (field) {
        case 'name':
          return 'offer_translation.name';
        case 'nameHotel':
          return 'hotel_translation.name';
        default:
          return field;
      }
    };

    if (sort) {
      setOrderDir(sort);
    }

    if (field) {
      setOrderBy(getOrderBy());
    }
  };

  const handleGridDisplayChange = () => {
    setDraft(!draft);
  };
  const optionsActive = [
    { key: '-1', value: t('pages.ranking.select-active.all') },
    { key: '1', value: t('pages.ranking.select-active.active') },
    { key: '0', value: t('pages.ranking.select-active.inactive') },
  ];

  return (
    <div>
      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid item xs={12}>
          <EditRanking
            id={ranking}
            setRanking={setRanking}
            setOffset={setOffset}
            setOrderBy={setOrderBy}
            setOrderDir={setOrderDir}
            options={options}
            activeRanking={activeRanking}
          />
        </Grid>
        <Grid item xs={12}>
          <ReindexRankingForm refetch={refetch} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            gridTemplateColumns={{ xs: '150px', md: '150px auto' }}
            display="grid"
            columnGap={2}
          >
            <Select
              onChange={(e) => setActive(e?.target?.value)}
              value={active}
            >
              {optionsActive.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              checked={draft}
              control={<Switch onChange={handleGridDisplayChange} />}
              label={<Typography>Pokaż na podstawie draftu</Typography>}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GridRanking
            handlePagination={handlePagination}
            offset={offset}
            limit={limit}
            loading={isLoading}
            activeRanking={activeRanking?.service as Services}
            count={data?.offers?.count || 0}
            rows={rows}
            ref={ref}
            isDraft={draft}
            onSortModelChange={onSortModelChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};
