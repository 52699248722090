import { useQuery } from '@tanstack/react-query';
import { queryStore } from 'api/gql/queries/queryStore';
import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'routes/paths';
import { useRole } from 'utils/useRole';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const role = useRole();
  const { isLoading, isError } = useQuery({
    ...queryStore.user.isLogged(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if ((!role && !isLoading) || isError) {
      navigate(paths.auth.login);
    }
  }, [role, navigate, pathname, isLoading, isError]);

  if (!role || isLoading) {
    return null;
  }

  return children;
};
