import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import { GetCitiesQueryVariables, GetCitiesQuery } from './generated/cities';

export const getCities = (props: GetCitiesQueryVariables) =>
  gqlClient.request<GetCitiesQuery>(GET_CITIES, props);

export const GET_CITIES = gql`
  query getCities(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getCities(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        name
        latitude
        longitude
        pl: translate(locale: "pl") {
          name
        }
        en: translate(locale: "en") {
          name
        }
      }
      count
    }
  }
`;
