import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useEffect, useRef } from 'react';
import { useMutationAddFunds } from '../gql/addFunds';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  id: number;
  initialValues: { id: string; coins: number; comment: string };
  refetch: () => void;
}

export const AddFundsDialog = ({
  open,
  handleCloseDialog,
  id,
  initialValues,
  refetch,
}: Props) => {
  const { mutate, isPending } = useMutationAddFunds();
  const { t } = useTranslation();
  const refCoins = useRef<any>();
  const validationSchema = yup.object().shape({
    coins: yup.string(),
    comment: yup.string(),
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        refCoins?.current?.focus();
      }, 100);
    }
  }, [open, refCoins]);

  const formik = useFormik<Props['initialValues']>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ coins, comment }) => {
      mutate(
        {
          id,
          coins,
          comment,
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            setTimeout(() => {
              refetch();
            }, 1000);
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>{t('pages.users.add-founds')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            sx={{ mt: 1 }}
            inputRef={refCoins}
            label={t('pages.users.coins')}
            id="coins"
            type="number"
            value={formik.values.coins || ''}
            onChange={formik.handleChange}
            fullWidth
          />
          <TextField
            placeholder={t('pages.users.comment')}
            multiline
            fullWidth
            minRows={4}
            name="comment"
            id="comment"
            value={formik.values.comment || ''}
            onChange={formik.handleChange}
            sx={{ mt: 1 }}
          />
          <Grid
            container
            item
            spacing={1}
            justifyContent="flex-end"
            textAlign="center"
            mt={1}
          >
            <Grid item>
              <LoadingButton
                disabled={!formik?.isValid}
                loading={isPending}
                variant="contained"
                type="submit"
              >
                {t('save')}
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleCloseDialog}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
