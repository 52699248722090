import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateOfferCalendarStaysMutation,
  UpdateOfferCalendarStaysMutationVariables,
} from './generated/updateOfferCalendarStays';

export const useUpdateOfferCalendarStays = () => {
  const data = useMutation<
    UpdateOfferCalendarStaysMutation,
    any,
    UpdateOfferCalendarStaysMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(UPDATE_OFFER_CALENDAR_STAYS, params),
  });
  return data;
};

export const UPDATE_OFFER_CALENDAR_STAYS = gql`
  mutation updateOfferCalendarStays(
    $id: Int!
    $input: CalendarStayUpdateInput!
  ) {
    updateOfferCalendarStays(id: $id, input: $input)
  }
`;
