import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateExcludeTypeMutation,
  UpdateExcludeTypeMutationVariables,
} from './generated/updateExcludeType';

export const useMutationUpdateExcludeType = () => {
  const data = useMutation<
    UpdateExcludeTypeMutation,
    any,
    UpdateExcludeTypeMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(UPDATE_EXCLUDE_TYPE_MUTATION, params),
  });
  return data;
};

export const UPDATE_EXCLUDE_TYPE_MUTATION = gql`
  mutation updateExcludeType($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateExcludeType(id: $id, parameters: $parameters) {
      id
      migrationId
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
