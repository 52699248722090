import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { months, years } from 'utils/dateUtils';
import * as yup from 'yup';
import { useMutationCreateCategory } from '../gql/createCategory';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  refetch: () => void;
}

const monthOptions = [{ value: 0, label: '-' }, ...months];
const yearOptions = [{ value: 0, label: '-' }, ...years];

export const CreateCategoryDialog = ({
  open,
  handleCloseDialog,
  refetch,
}: Props) => {
  const { mutate } = useMutationCreateCategory();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    en: yup.string(),
    pl: yup.string(),
    defaultMonth: yup.number().notRequired(),
    defaultYear: yup.number().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      pl: '',
      en: '',
      defaultMonth: null,
      defaultYear: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (value, { resetForm }) => {
      mutate(
        {
          parameters: [
            {
              key: 'name',
              type: 'Translation',
              locale: 'pl',
              stringValue: value.pl,
            },
            {
              key: 'name',
              type: 'Translation',
              locale: 'en',
              stringValue: value.en,
            },
            {
              key: 'defaultYear',
              type: 'Int',
              intValue: Number(value.defaultYear),
            },
            {
              key: 'defaultMonth',
              type: 'Int',
              intValue: Number(value.defaultMonth),
            },
          ],
        },
        {
          onSuccess: () => {
            refetch();
            resetForm();
            handleCloseDialog();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            resetForm();
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Dodaj Kategorie</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid container spacing={1}>
            <Grid container item spacing={1} direction="column">
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="Polskie tłumaczenie"
                  name="pl"
                  id="pl"
                  value={formik.values.pl || ''}
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="Angielskie tłumaczenie"
                  name="en"
                  id="en"
                  value={formik.values.en || ''}
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <FormControl fullWidth>
                  <InputLabel id="type">
                    {t('pages.categories.defaultMonth')}
                  </InputLabel>
                  <Select
                    fullWidth
                    onChange={(event) => {
                      formik.setFieldValue('defaultMonth', event.target.value);
                    }}
                    value={formik.values.defaultMonth}
                    label={t('pages.categories.defaultMonth')}
                  >
                    {monthOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <FormControl fullWidth>
                  <InputLabel id="type">
                    {t('pages.categories.defaultYear')}
                  </InputLabel>
                  <Select
                    fullWidth
                    onChange={(event) => {
                      formik.setFieldValue('defaultYear', event.target.value);
                    }}
                    value={formik.values.defaultYear}
                    label="Rok"
                    placeholder={t('pages.categories.defaultYear')}
                  >
                    {yearOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  disabled={!formik?.isValid}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
