import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateCancellationContentMutation,
  CreateCancellationContentMutationVariables,
} from './generated/createCancellationContent';

export const useMutationCreateCancellationContent = () => {
  const data = useMutation<
    CreateCancellationContentMutation,
    any,
    CreateCancellationContentMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(CREATE_CANCELLATION_CONTENT_MUTATION, params),
  });
  return data;
};

export const CREATE_CANCELLATION_CONTENT_MUTATION = gql`
  mutation createCancellationContent($parameters: [ConfigurationInput!]!) {
    createCancellationContent(parameters: $parameters) {
      id
      migrationId
      pl: translate(locale: "pl") {
        title
        content
      }
      en: translate(locale: "en") {
        title
        content
      }
    }
  }
`;
