import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetExcludeTypesQuery,
  GetExcludeTypesQueryVariables,
} from './generated/getExcludeTypes';

export const getExcludeTypes = (props: GetExcludeTypesQueryVariables) =>
  gqlClient.request<GetExcludeTypesQuery>(GET_EXCLUDE_TYPES, props);

export const GET_EXCLUDE_TYPES = gql`
  query getExcludeTypes(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getExcludeTypes(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        migrationId
        pl: translate(locale: "pl") {
          name
        }
        en: translate(locale: "en") {
          name
        }
      }
      count
    }
  }
`;
