import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { t } from 'i18next';
import { ChangeEvent, useState } from 'react';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { Level } from 'pages/segments/components/Level';
import { DefaultGridToolbar } from 'components/DataGrid';
import { AddFundsDialog } from './components/AddFundsDialog';
import { EditUsersDialog } from './components/EditUsersDialog';
import { gqlUsers } from './gql';
import { User } from './gql/types';

const emptyUser = {
  firstName: '',
  lastName: '',
  loyaltyCardNumber: '',
  email: '',
  phone: '',
  segment: undefined,
  active: false,
};

export const Users = () => {
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const [sortBy, setSortBy] = useQueryParamsState('sortBy', 'id');
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'desc');
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [openAddFundsDialog, setOpenAddFundsDialog] = useState(false);
  const [userId, setUserIdToEdit] = useState(0);
  const [userToEdit, setUserToEdit] = useState<User>(emptyUser);
  const [userToAddFunds, setUserToAddFunds] = useState({
    id: '',
    coins: 0,
    comment: '',
  });
  const [searchPhrase, setSearchPhrase] = useState('');

  const { data, isLoading, refetch } = useQuery({
    ...gqlUsers.getUsers({
      offset: offset * limit,
      limit,
      orderBy: sortBy,
      orderDir: sortDir,
      phrase: searchPhrase,
      conditions: [
        {
          column: 'user.roles',
          value: 'ROLE_USER',
          operator: 'like',
          type: 'AND',
        },
      ],
    }),
  });

  const handleSearchInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.preventDefault();
    setSearchPhrase(event.target.value);
  };

  const handleOpenEditUserDialog = () => {
    setOpenEditUserDialog(true);
  };

  const handleOpenAddFundsDialog = () => {
    setOpenAddFundsDialog(true);
  };

  const handleCloseEditUserDialog = () => {
    setUserToEdit(emptyUser);
    setOpenEditUserDialog(false);
  };

  const handleCloseAddFundsDialog = () => {
    setUserToAddFunds({ id: '', coins: 0, comment: '' });
    setOpenAddFundsDialog(false);
  };

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    const { field, sort } = item || {};
    const getKeySort = () => {
      switch (field) {
        case 'x':
          return 'x';
        default:
          return field;
      }
    };

    if (sort) {
      setSortDir(sort);
    }

    if (field) {
      setSortBy(getKeySort());
    }
  };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.users.id'),
      ...defaultProps,
      minWidth: 90,
      maxWidth: 90,
      resizable: false,
      sortable: true,
    },
    {
      field: 'firstName',
      headerName: t('pages.users.firstName'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'lastName',
      headerName: t('pages.users.lastName'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'email',
      headerName: t('pages.users.email'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'phone',
      headerName: t('pages.users.phone'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'migrationId',
      headerName: t('pages.users.migrationId'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'updatedAt',
      headerName: t('pages.users.updatedAt'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'identifier',
      headerName: t('pages.users.identifier'),
      ...defaultProps,
    },
    {
      field: 'active',
      headerName: t('pages.users.active.header'),
      type: 'boolean',
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'fundsEnabledTotal',
      headerName: t('pages.users.funds'),
      ...defaultProps,
      // sortable: true,
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>{params.row.fundsEnabledTotal}</div>
          <Tooltip title={t('pages.users.add-founds')}>
            <IconButton
              onClick={handleOpenAddFundsDialog}
              disabled={!params.row.loyalty}
              sx={{ ml: '5px' }}
              size="small"
              color="primary"
            >
              <PlusOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'loyaltyCardNumber',
      headerName: t('pages.users.loyaltyCardNumber'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'segment',
      headerName: t('pages.users.segment'),
      ...defaultProps,
      sortable: true,
      renderCell: (params) => <Level type={params.value}>{params.value}</Level>,
    },
    {
      field: 'edit',
      headerName: t('pages.users.edit'),
      ...defaultProps,
      hideable: false,
      renderCell: () => (
        <IconButton onClick={handleOpenEditUserDialog}>
          <EditOutlined />
        </IconButton>
      ),
    },
  ];

  const rows =
    data?.users?.items?.map((row) => ({
      id: row.id,
      firstName: row.firstName,
      lastName: row.lastName,
      email: row.email,
      phone: row.phone,
      migrationId: row.migrationId,
      active: row.active,
      updatedAt: row.updatedAt,
      identifier: row.identifier,
      loyalty: row.loyalty,
      segment: row.segment?.symbol,
      fundsEnabledTotal: row.fundsEnabledTotal,
      loyaltyCardNumber: row.loyaltyCardNumber,
    })) || [];

  const setUser = (param: {
    id: any;
    row: {
      firstName: string;
      lastName: string;
      loyaltyCardNumber: any;
      email: any;
      phone: any;
      segment: number;
      active: boolean;
    };
  }) => {
    const clickedUser: User = {
      firstName: param.row.firstName,
      lastName: param.row.lastName,
      loyaltyCardNumber: param.row.loyaltyCardNumber,
      email: param.row.email,
      phone: param.row.phone,
      segment: param.row.segment,
      active: param.row.active,
    };

    setUserIdToEdit(param.id);
    setUserToEdit(clickedUser);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <h2>Użytkownicy</h2>
      </Grid>
      <Grid item>
        <Box>
          <SearchBar
            placeholder={t('pages.users.search-user')}
            value={searchPhrase}
            handleSearchInputChange={handleSearchInputChange}
          />
        </Box>
      </Grid>
      <Grid item width="100%">
        <DataGrid
          loading={isLoading}
          disableRowSelectionOnClick
          rows={rows}
          columns={columns}
          autoHeight
          pageSizeOptions={[10, 25, 50, 75]}
          paginationMode="server"
          paginationModel={{ page: offset, pageSize: limit }}
          onPaginationModelChange={handlePagination}
          rowCount={data?.users.count || 0}
          sortingMode="server"
          onSortModelChange={onSortModelChange}
          onRowClick={setUser}
          disableColumnMenu
          slots={{
            toolbar: DefaultGridToolbar,
          }}
        />
      </Grid>
      <AddFundsDialog
        key={userId}
        open={openAddFundsDialog}
        id={userId}
        handleCloseDialog={handleCloseAddFundsDialog}
        initialValues={userToAddFunds}
        refetch={refetch}
      />
      <EditUsersDialog
        key={userId}
        open={openEditUserDialog}
        id={userId}
        handleCloseDialog={handleCloseEditUserDialog}
        initialValues={userToEdit}
        refetch={refetch}
      />
    </Grid>
  );
};
