import { lazy } from 'react';
import { Loadable } from 'components/Loadable';
import { MainLayout } from 'layout/MainLayout';
import { Hotels } from 'pages/hotels';
import { Ranking } from 'pages/ranking/Ranking';
import { Segments } from 'pages/segments/Segments';
import { Users } from 'pages/users/Users';
import { Admins } from 'pages/users/Admins';
import { Subscribers } from 'pages/users/Subscribers';
import { HotelOffers } from 'pages/hotels/HotelOffers';
import { Dictionaries } from 'pages/dictionaries/Dictionaries';
import { CurrenciesDictionary } from 'pages/dictionaries/CurrenciesDictionary';
import { RoomTypesDictionary } from 'pages/dictionaries/RoomTypesDictionary';
import { BedTypesDictionary } from 'pages/dictionaries/BedTypesDictionary';
import { CitiesDictionary } from 'pages/dictionaries/CitiesDictionary';
import { ProvincesDictionary } from 'pages/dictionaries/ProvincesDictionary';
import { WebhookLogsDictionary } from 'pages/dictionaries/WebhookLogsDictionary';
import { ExcludeTypesDictionary } from 'pages/dictionaries/ExcludeTypesDictionary';
import { IncludeTypesDictionary } from 'pages/dictionaries/IncludeTypesDictionary';
import { CancellationContentDictionary } from 'pages/dictionaries/CancellationContentDictionary';
import { CategoriesDictionary } from 'pages/dictionaries/CategoriesDictionary';
import { RankingLogsDictionary } from 'pages/dictionaries/RankingLogsDictionary';
import { HotelOfferDetails } from 'pages/hotels/HotelOfferDetails';
import { UpdateUsersGroup } from 'pages/users/UpdateUsersGroup';
import { UsersGroups } from 'pages/users/UsersGroups';
import { AddUsersGroup } from 'pages/users/AddUsersGroup';
import { paths } from './paths';
import { AuthGuardRoute } from './AuthGuardRoute';

const DashboardDefault = Loadable(
  lazy(() =>
    import('pages/dashboard').then((mod) => ({
      default: mod.DashboardDefault,
    })),
  ),
);

export const MainRoutes = {
  path: paths.base,
  element: <MainLayout />,
  children: [
    {
      path: paths.base,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <DashboardDefault />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.hotels,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <Hotels />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.hotelsOffers,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <HotelOffers />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.hotelsOfferDetails,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <HotelOfferDetails />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.ranking,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <Ranking />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.loyalty,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <Segments />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.users,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <Users />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.usersAdmins,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <Admins />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.usersSubscribers,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <Subscribers />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.usersGroups,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <UsersGroups />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.updateUserGroup,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <UpdateUsersGroup />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.addUserGroup,
      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <AddUsersGroup />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.dictionaries,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <Dictionaries />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.cities,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <CitiesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.currencies,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <CurrenciesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.bedTypes,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <BedTypesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.roomTypes,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <RoomTypesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.excludeTypes,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <ExcludeTypesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.includeTypes,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <IncludeTypesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.provinces,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <ProvincesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.webhookLogs,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <WebhookLogsDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.rankingLogs,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <RankingLogsDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.categories,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <CategoriesDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.cancellationContent,

      element: (
        <AuthGuardRoute allowedRoles={['ADMIN']}>
          <CancellationContentDictionary />
        </AuthGuardRoute>
      ),
    },
    {
      path: paths.main.notAllowed,
      element: <div>Sorry, You Are Not Allowed to Access This Page</div>,
    },
    {
      path: paths.all,
      element: <div>Error</div>,
    },
  ],
};
