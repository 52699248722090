import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  HotelOffersQueryVariables,
  HotelOffersQuery,
} from './generated/hotelOffers';

export const getHotelOffers = (props: HotelOffersQueryVariables) =>
  gqlClient.request<HotelOffersQuery>(GET_HOTEL_OFFERS, props);

export const GET_HOTEL_OFFERS = gql`
  query hotelOffers(
    $id: String!
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDir: String
  ) {
    offers(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
      conditions: [
        { column: "offer_hotel.id", value: $id, operator: "eq", type: "AND" }
      ]
    ) {
      items {
        id
        translate {
          name
        }
        migrationId
        rooms {
          id
          translate {
            name
          }
        }
        startDate
        endDate
        active
        updatedAt
        url
      }
      count
    }
  }
`;
