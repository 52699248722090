import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import {
  ArrowRightOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { gqlDictionaries } from './gql';

export const RankingLogsDictionary = () => {
  const { data, isLoading } = useQuery({
    ...gqlDictionaries.rankingTypes({}),
    refetchOnWindowFocus: false,
  });

  const rows = data?.rankingTypes?.map((row) => ({
    id: row.id,
    migrationId: row.id,
    type: row.name,
    createdAt: 'row',
    auditLogs: row.auditLogs,
  }));

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        {rows?.map((row) => (
          <Grid item>
            <Stack spacing={2}>
              <Typography variant="h5" color="inherit">
                {row.type}
              </Typography>
              {row.auditLogs.length ? (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox" />
                        <TableCell>Użytkownik</TableCell>
                        <TableCell align="right">Data</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.auditLogs.map((auditLog: any) => (
                        <ExpandableTableRow
                          key={auditLog.user}
                          expandComponent={
                            <ExpandRow changeset={auditLog.changeset} />
                          }
                        >
                          <TableCell>{auditLog.user}</TableCell>
                          <TableCell align="right">{auditLog.date}</TableCell>
                        </ExpandableTableRow>
                      ))}
                    </TableBody>
                  </Table>{' '}
                </TableContainer>
              ) : (
                <div>Brak danych</div>
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const ExpandRow = ({ changeset }: any) => {
  if (changeset?.relatedDraft) {
    return (
      <TableCell valign="middle" colSpan={5}>
        <Chip
          color="error"
          label={JSON.stringify(changeset.relatedDraft.old)}
        />
        <ArrowRightOutlined style={{ padding: '0 8px' }} />
        <Chip
          color="success"
          label={JSON.stringify(changeset.relatedDraft.new)}
        />
      </TableCell>
    );
  }

  if (changeset?.configuration) {
    return (
      <TableCell valign="middle" colSpan={5}>
        <Stack justifyContent="space-between" direction="row" spacing={2}>
          <Item changeset={changeset} valueKey="weight" label="Waga główna" />
          <Item
            changeset={changeset}
            valueKey="weight_0"
            label="Waga (dzisiaj)"
          />
          <Item
            changeset={changeset}
            valueKey="weight_1"
            label="Waga (wczoraj)"
          />
          <Item
            changeset={changeset}
            valueKey="weight_3"
            label="Waga (3 dni)"
          />
          <Item
            changeset={changeset}
            valueKey="weight_7"
            label="Waga (7 dni)"
          />
        </Stack>
      </TableCell>
    );
  }

  return <TableCell colSpan={5}>{' - '}</TableCell>;
};

const Item = ({
  label,
  changeset,
  valueKey,
}: {
  label: string;
  changeset: any;
  valueKey: string;
}) => {
  const oldValue = JSON.parse(changeset.configuration.old)[valueKey];
  const newValue = JSON.parse(changeset.configuration.new)[valueKey];

  return (
    <Box>
      <p>{label}</p>
      <Chip
        color={oldValue === newValue ? 'default' : 'error'}
        label={oldValue}
      />
      <ArrowRightOutlined style={{ padding: '0 8px' }} />
      <Chip
        color={oldValue === newValue ? 'default' : 'success'}
        label={newValue}
      />
    </Box>
  );
};

const ExpandableTableRow = ({
  children,
  expandComponent,
  ...otherProps
}: any) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <UpOutlined /> : <DownOutlined />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};
