import { ReactElement } from 'react';
import { canNavigate } from 'utils/canNavigate';
import { Navigate } from 'react-router-dom';
import { useRole } from 'utils/useRole';
import { Roles } from 'types';
import { paths } from './paths';

type AuthGuardRouteProps = {
  children: ReactElement;
  allowedRoles: Roles[];
};

export const AuthGuardRoute = ({
  children,
  allowedRoles,
}: AuthGuardRouteProps) => {
  const role = useRole();

  if (!role) {
    return null;
  }

  return canNavigate(role, allowedRoles) ? (
    children
  ) : (
    <Navigate replace to={paths.main.notAllowed} />
  );
};
