import { Theme } from '@mui/material';

export function Chip(): Theme['components'] {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
  };
}
