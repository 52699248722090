import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables,
} from './generated/createUserGroup';

export const useMutationCreateUserGroup = () => {
  const data = useMutation<
    CreateUserGroupMutation,
    any,
    CreateUserGroupMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(CREATE_USER_GROUP, params),
  });
  return data;
};

export const CREATE_USER_GROUP = gql`
  mutation CreateUserGroup($parameters: [ConfigurationInput]) {
    createUserGroup(parameters: $parameters) {
      id
    }
  }
`;
