import { createQueryKeys } from '@lukemorales/query-key-factory';
import { RankingTypesQueryVariables } from 'pages/ranking/gql/generated/rankingTypes';
import { getRankingTypes } from 'pages/ranking/gql/rankingTypes';
import { getCities } from './cities';
import { GetCitiesQueryVariables } from './generated/cities';
import { getCurrencies } from './getCurrencies';
import { GetCurrenciesQueryVariables } from './generated/getCurrencies';
import { GetBedTypesQueryVariables } from './generated/getBedTypes';
import { getBedTypes } from './getBedTypes';
import { GetRoomTypesQueryVariables } from './generated/getRoomTypes';
import { getRoomTypes } from './getRoomTypes';
import { getExcludeTypes } from './getExcludeTypes';
import { GetExcludeTypesQueryVariables } from './generated/getExcludeTypes';
import { getProvinces } from './getProvinces';
import { getWebhookLogs } from './getWebhookLogs';
import { GetProvincesQueryVariables } from './generated/getProvinces';
import { GetWebhookLogsQueryVariables } from './generated/getWebhookLogs';
import { getIncludeTypes } from './getIncludeTypes';
import { GetIncludeTypesQueryVariables } from './generated/getIncludeTypes';
import { getCancellationContents } from './getCancellationContents';
import { GetCancellationContentsQueryVariables } from './generated/getCancellationContents';
import { getCategories } from './getCategories';
import { GetCategoriesQueryVariables } from './generated/getCategories';

export const gqlDictionaries = createQueryKeys('hotels', {
  getCities: (props: GetCitiesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getCities(props),
  }),
  getCurrencies: (props: GetCurrenciesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getCurrencies(props),
  }),
  getBedTypes: (props: GetBedTypesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getBedTypes(props),
  }),
  getRoomTypes: (props: GetRoomTypesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getRoomTypes(props),
  }),
  getExcludeTypes: (props: GetExcludeTypesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getExcludeTypes(props),
  }),
  getIncludeTypes: (props: GetIncludeTypesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getIncludeTypes(props),
  }),
  getProvinces: (props: GetProvincesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getProvinces(props),
  }),
  getWebhookLogs: (props: GetWebhookLogsQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getWebhookLogs(props),
  }),
  getCancellationContents: (props: GetCancellationContentsQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getCancellationContents(props),
  }),
  getCategories: (props: GetCategoriesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getCategories(props),
  }),
  rankingTypes: (props: RankingTypesQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getRankingTypes(props),
  }),
});
export { getCities, getCurrencies, getBedTypes, getRoomTypes };
