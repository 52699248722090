import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { getLogged } from './isLogged/gql';
import { getMe } from './me/gql';

export const queryStore = createQueryKeyStore({
  user: {
    isLogged: () => ({
      queryKey: ['login'],
      queryFn: () => getLogged(),
    }),
    me: () => ({
      queryKey: ['me'],
      queryFn: () => getMe(),
    }),
  },
});
