import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateIncludeTypeMutation,
  CreateIncludeTypeMutationVariables,
} from './generated/createIncludeType';

export const useMutationCreateIncludeType = () => {
  const data = useMutation<
    CreateIncludeTypeMutation,
    any,
    CreateIncludeTypeMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(CREATE_INCLUDE_TYPE, params),
  });
  return data;
};

export const CREATE_INCLUDE_TYPE = gql`
  mutation createIncludeType($parameters: [ConfigurationInput!]!) {
    createIncludeType(parameters: $parameters) {
      id
      migrationId
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
