import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { DefaultGridToolbar } from 'components/DataGrid';
import { gqlHotels } from './gql';
import { useMutationToggleHotelOptions } from './gql/toggleHotelOptions';

export const Hotels = () => {
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const [sortBy, setSortBy] = useQueryParamsState(
    'sortBy',
    'hotel_translation.name',
  );
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'desc');
  const [searchPhrase, setSearchPhrase] = useState('');
  const { mutate } = useMutationToggleHotelOptions();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    ...gqlHotels.getHotels({
      offset: offset * limit,
      limit,
      orderBy: sortBy,
      orderDir: sortDir,
      phrase: searchPhrase,
    }),
  });

  const client = useQueryClient();
  const refetch = () =>
    client.refetchQueries({
      ...gqlHotels.getHotels({
        offset: offset * limit,
        limit,
        orderBy: sortBy,
        orderDir: sortDir,
        phrase: searchPhrase,
      }),
    });

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    const { field, sort } = item || {};
    const getKeySort = () => {
      switch (field) {
        case 'name':
          return 'hotel_translation.name';
        case 'id':
          return 'hotel.id';
        default:
          return field;
      }
    };

    if (sort) {
      setSortDir(sort);
    }

    if (field) {
      setSortBy(getKeySort());
    }
  };

  const handleSearchInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.preventDefault();
    setSearchPhrase(event.target.value);
  };

  const handleCheckboxChange = (
    id: number | undefined,
    field: string,
    value: boolean,
  ) => {
    if (!id) {
      enqueueSnackbar({
        message: 'Wrong id',
        variant: 'error',
      });
      return;
    }
    mutate(
      {
        id,
        parameters: [{ key: field, type: 'Boolean', boolValue: value }],
      },
      {
        onSuccess: () => {
          refetch();
          enqueueSnackbar({
            message: t('message.success.changes-saved'),
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar({
            message: t('message.error.error-occured'),
            variant: 'error',
          });
        },
      },
    );
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate(`/hotels/offers?hotelId=${params.id}`);
  };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.hotels.id'),
      ...defaultProps,
      minWidth: 90,
      maxWidth: 90,
      resizable: false,
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('pages.hotels.name'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'migrationId',
      headerName: t('pages.hotels.migrationId'),
      ...defaultProps,
      sortable: true,
    },
    {
      field: 'rooms',
      headerName: t('pages.hotels.rooms'),
      ...defaultProps,
    },
    {
      field: 'offers',
      headerName: t('pages.hotels.offers'),
      ...defaultProps,
    },
    {
      field: 'active',
      headerName: t('pages.hotels.active.header'),
      ...defaultProps,
    },
    {
      field: 'expose',
      headerName: t('pages.hotels.expose.header'),
      display: 'flex',
      ...defaultProps,
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => (
        <Grid container direction="column">
          <FormControlLabel
            control={
              <Checkbox
                checked={params.row?.expose?.dedge}
                onChange={
                  (event) =>
                    handleCheckboxChange(
                      params?.row?.id,
                      'expose.dedge',
                      event.target.checked,
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            }
            label={t('pages.hotels.expose.dedge')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={params.row?.expose?.google}
                onChange={
                  (event) =>
                    handleCheckboxChange(
                      params?.row?.id,
                      'expose.google',
                      event.target.checked,
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            }
            label={t('pages.hotels.expose.google')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={params.row?.expose?.nekera}
                onChange={
                  (event) =>
                    handleCheckboxChange(
                      params?.row?.id,
                      'expose.nekera',
                      event.target.checked,
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            }
            label={t('pages.hotels.expose.nekera')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={params.row.taxEnabled}
                onChange={
                  (event) =>
                    handleCheckboxChange(
                      params?.row?.id,
                      'taxEnabled',
                      event.target.checked,
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            }
            label={t('pages.hotels.expose.taxEnabled')}
          />
        </Grid>
      ),
    },
  ];

  const rows =
    data?.hotels?.items?.map((row) => ({
      id: row.id,
      name: row.translate.name,
      migrationId: row.migrationId,
      rooms: row.rooms?.length,
      offers: row.offers?.length,
      active: row.active
        ? t('pages.hotels.active.active')
        : t('pages.hotels.active.inactive'),
      expose: row.expose,
      taxEnabled: row.taxEnabled,
    })) || [];

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item container>
          <Grid item>
            <Box>
              <SearchBar
                placeholder={t('pages.hotels.searchBarPlaceholder')}
                value={searchPhrase}
                handleSearchInputChange={handleSearchInputChange}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            autoHeight
            rows={rows}
            columns={columns}
            getRowHeight={() => 'auto'}
            pageSizeOptions={[10, 25, 50, 75]}
            paginationMode="server"
            paginationModel={{ page: offset, pageSize: limit }}
            onPaginationModelChange={handlePagination}
            rowCount={data?.hotels.count || 0}
            sortingOrder={['asc', 'desc']}
            sortingMode="server"
            onSortModelChange={onSortModelChange}
            slots={{
              toolbar: DefaultGridToolbar,
            }}
            sx={{
              '.MuiDataGrid-cell': { display: 'flex', alignItems: 'center' },
              '.MuiDataGrid-row': { cursor: 'pointer' },
            }}
            onRowClick={handleRowClick}
            onCellClick={(param, event) => {
              if (param.field === 'expose') {
                event.stopPropagation();
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
