import { gql } from 'graphql-request';
import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import {
  SaveRankingTypeMutation,
  SaveRankingTypeMutationVariables,
} from './generated/saveRankingType';

export const useMutationSaveRankingType = () => {
  const data = useMutation<
    SaveRankingTypeMutation,
    any,
    SaveRankingTypeMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(SAVE_RANKING_TYPE, params),
  });
  return data;
};

export const SAVE_RANKING_TYPE = gql`
  mutation saveRankingType($id: Int, $parameters: [ConfigurationInput]) {
    saveRankingType(id: $id, parameters: $parameters) {
      id
    }
  }
`;
