import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';

export const Logo = () => {
  const { drawerOpen } = useSelector((state: Store) => state.menu);
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  if (!matchDownMD && !drawerOpen) {
    return (
      <img src="/logo/logo_no_text.svg" alt="Triverna" width="30" height={20} />
    );
  }

  return (
    <img
      src={`/logo/${theme.palette.mode}.svg`}
      alt="Triverna"
      width="150"
      height={20}
    />
  );
};
