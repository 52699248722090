import { Grid } from '@mui/material';
import { Outlet } from 'react-router';
import { LangChanger } from 'layout/MainLayout/components/LangChanger';
import { LogoSection } from 'layout/MainLayout/components/Logo';
import { AuthCard } from './AuthCard';

export const AuthLayout = () => (
  <Grid
    container
    sx={{
      px: 3,
      width: '100%',
      height: 'calc(100vh - 60px)',
    }}
    alignItems="center"
  >
    <Grid container xs={6}>
      <LogoSection sx={{ height: '60px' }} />
    </Grid>
    <Grid container xs={6} justifyContent="flex-end">
      <LangChanger />
    </Grid>
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Grid item>
        <AuthCard>
          <Outlet />
        </AuthCard>
      </Grid>
    </Grid>
  </Grid>
);
