/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ThemeMode } from 'types/config';
import { LinkTarget, NavActionType, NavItemProps } from 'types/menu';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { activeItem, openDrawer } from 'store/reducers/menu';
import IconButton from 'components/@extended/IconButton';
import { getName } from './utils';

interface Props {
  item: NavItemProps;
  level: number;
  isParents?: boolean;
}

export const NavItem = ({ item, level, isParents = false }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state: Store) => state.menu);
  const { drawerOpen, openItem } = menu;

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const Icon = item.icon!;
  const itemIcon = item.icon ? (
    <Icon
      style={{
        fontSize: drawerOpen ? '1rem' : '1.25rem',
        ...(isParents && { fontSize: 20, stroke: '1.5' }),
      }}
    />
  ) : (
    false
  );

  const { pathname } = useLocation();
  const isSelectedHome = pathname === '/' && item.url === '/';
  const isSelected =
    ((!!matchPath({ path: item.url!, end: false }, pathname) ||
      openItem === item.id) &&
      item.url !== '/') ||
    isSelectedHome;

  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item?.url || '')) {
      dispatch(activeItem({ openItem: item.id }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor =
    theme.palette.mode === ThemeMode.DARK ? 'grey.400' : 'text.primary';
  const iconSelectedColor =
    theme.palette.mode === ThemeMode.DARK && drawerOpen
      ? 'text.primary'
      : 'primary.main';

  function handlerDrawerOpen(open: boolean): void {
    dispatch(openDrawer({ drawerOpen: !open }));
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <ListItemButton
        component={Link}
        to={item.url!}
        target={itemTarget}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor:
                theme.palette.mode === ThemeMode.DARK
                  ? 'divider'
                  : 'primary.lighter',
            },
            '&.Mui-selected': {
              bgcolor:
                theme.palette.mode === ThemeMode.DARK
                  ? 'divider'
                  : 'primary.lighter',
              borderRight: `2px solid ${theme.palette.primary.main}`,
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor:
                  theme.palette.mode === ThemeMode.DARK
                    ? 'divider'
                    : 'primary.lighter',
              },
            },
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent',
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent',
              },
              bgcolor: 'transparent',
            },
          }),
        }}
        {...(downLG && {
          onClick: () => handlerDrawerOpen(false),
        })}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isSelected ? iconSelectedColor : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor:
                    theme.palette.mode === ThemeMode.DARK
                      ? 'secondary.light'
                      : 'secondary.lighter',
                },
              }),
              ...(!drawerOpen &&
                isSelected && {
                  bgcolor:
                    theme.palette.mode === ThemeMode.DARK
                      ? 'primary.900'
                      : 'primary.lighter',
                  '&:hover': {
                    bgcolor:
                      theme.palette.mode === ThemeMode.DARK
                        ? 'primary.darker'
                        : 'primary.lighter',
                  },
                }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{ color: isSelected ? iconSelectedColor : textColor }}
              >
                {getName(item.name)}
              </Typography>
            }
          />
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
      {(drawerOpen || (!drawerOpen && level !== 1)) &&
        item?.actions?.map((action, index) => {
          const ActionIcon = action.icon!;
          const callAction = action?.function;
          return (
            <IconButton
              key={index}
              {...(action?.type === NavActionType.FUNCTION && {
                onClick: (event) => {
                  event.stopPropagation();
                  callAction();
                },
              })}
              {...(action?.type === NavActionType.LINK && {
                component: Link,
                to: action.url,
                target: action.target ? '_blank' : '_self',
              })}
              color="secondary"
              variant="outlined"
              sx={{
                position: 'absolute',
                top: 12,
                right: 20,
                zIndex: 1202,
                width: 20,
                height: 20,
                mr: -1,
                ml: 1,
                color: 'secondary.dark',
                borderColor: isSelected ? 'primary.light' : 'secondary.light',
                '&:hover': {
                  borderColor: isSelected ? 'primary.main' : 'secondary.main',
                },
              }}
            >
              <ActionIcon style={{ fontSize: '0.625rem' }} />
            </IconButton>
          );
        })}
    </Box>
  );
};
