import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import { Breadcrumbs } from 'components/@extended/Breadcrumbs';
import { openDrawer } from 'store/reducers/menu';
import { Store } from 'store/reducers';
import { AuthProvider } from 'providers/AuthProvider';
import { drawerWidth } from 'config';
import { PusherProvider } from 'providers/Pusher';
import { Header } from './Header';
import { Drawer } from './Drawer';

export const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state: Store) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <AuthProvider>
      <PusherProvider>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Header open={open} handleDrawerToggle={handleDrawerToggle} />
          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box
            component="main"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              flexGrow: 1,
              p: { xs: 2, sm: 3 },
            }}
          >
            <Toolbar />
            <Breadcrumbs />
            <Outlet />
          </Box>
        </Box>
      </PusherProvider>
    </AuthProvider>
  );
};
