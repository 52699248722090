import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/dist/simplebar.css';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store';
import App from './App';
import './i18n';
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>,
);
