import { createQueryKeys } from '@lukemorales/query-key-factory';
import { UsersQueryVariables } from './generated/users';
import { getUsers } from './users';
import { ContactsQueryVariables } from './generated/contacts';
import { getUsersWithSubscription } from './contacts';
import { GetUserGroupsQueryVariables } from './generated/userGroups';
import { getUsersGroups } from './userGroups';
import { GetPermissionsQueryVariables } from './generated/getPermission';
import { getPermissions } from './getPermission';
import { getUsersGroup } from './userGroup';
import { GetUserGroupQueryVariables } from './generated/userGroup';

export const gqlUsers = createQueryKeys('users', {
  getUsers: (props: UsersQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getUsers(props),
  }),
  getUsersWithSubscription: (props: ContactsQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getUsersWithSubscription(props),
  }),
  getUsersGroups: (props: GetUserGroupsQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getUsersGroups(props),
  }),
  getUsersGroup: (props: GetUserGroupQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getUsersGroup(props),
  }),
  getPermissions: (props?: GetPermissionsQueryVariables) => ({
    queryKey: [props],
    queryFn: () => getPermissions(props),
  }),
});
