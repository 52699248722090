import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { LOGOUT } from './gql';
import { LogoutMutation } from './generated/gql';

export const useMutationLogout = () => {
  const data = useMutation<LogoutMutation, any>({
    mutationFn: () => gqlClient.request(LOGOUT),
  });
  return data;
};
