import { paths } from 'routes/paths';
import { CorporateFare } from '@mui/icons-material';
import {
  DashboardOutlined,
  RiseOutlined,
  UserOutlined,
  TranslationOutlined,
  FundProjectionScreenOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { NavItemProps } from 'types/menu';

export const main: NavItemProps = {
  id: 'examples',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      name: 'paths.dashboard',
      type: 'item',
      url: paths.main.dashboard,
      icon: DashboardOutlined,
    },
    {
      id: 'ranking',
      name: 'paths.ranking',
      type: 'item',
      url: paths.main.ranking,
      icon: RiseOutlined,
    },
    {
      id: 'hotels',
      name: 'paths.hotels',
      type: 'item',
      url: paths.main.hotels,
      icon: CorporateFare,
    },
    {
      id: 'loyalty',
      name: 'paths.loyalty',
      type: 'item',
      url: paths.main.loyalty,
      icon: DollarOutlined,
    },
    {
      id: 'users-management',
      name: 'paths.users-management',
      type: 'collapse',
      url: paths.main.usersManagement,
      icon: UserOutlined,
      children: [
        {
          id: 'users',
          name: 'paths.users',
          type: 'item',
          url: paths.main.users,
        },
        {
          id: 'admins',
          name: 'paths.admins',
          type: 'item',
          url: paths.main.usersAdmins,
        },
        {
          id: 'subscribers',
          name: 'paths.subscribers',
          type: 'item',
          url: paths.main.usersSubscribers,
        },
        {
          id: 'usersGroups',
          name: 'paths.users-groups',
          type: 'item',
          url: paths.main.usersGroups,
        },
      ],
    },
    {
      id: 'dictionaries',
      name: 'paths.dictionaries',
      type: 'collapse',
      url: paths.main.dictionaries,
      icon: TranslationOutlined,
      children: [
        {
          id: 'cities',
          name: 'paths.cities',
          type: 'item',
          url: paths.main.cities,
        },
        {
          id: 'currencies',
          name: 'paths.currencies',
          type: 'item',
          url: paths.main.currencies,
        },
        {
          id: 'bedTypes',
          name: 'paths.bedTypes',
          type: 'item',
          url: paths.main.bedTypes,
        },
        {
          id: 'roomTypes',
          name: 'paths.roomTypes',
          type: 'item',
          url: paths.main.roomTypes,
        },
        {
          id: 'excludeTypes',
          name: 'paths.excludeTypes',
          type: 'item',
          url: paths.main.excludeTypes,
        },
        {
          id: 'includeTypes',
          name: 'paths.includeTypes',
          type: 'item',
          url: paths.main.includeTypes,
        },
        {
          id: 'provinces',
          name: 'paths.provinces',
          type: 'item',
          url: paths.main.provinces,
        },
        {
          id: 'cancellationContent',
          name: 'paths.cancellationContent',
          type: 'item',
          url: paths.main.cancellationContent,
        },
        {
          id: 'categories',
          name: 'paths.categories',
          type: 'item',
          url: paths.main.categories,
        },
      ],
    },
    {
      id: 'maintenance',
      name: 'paths.maintenance',
      type: 'collapse',
      url: paths.main.maintenance,
      icon: FundProjectionScreenOutlined,
      children: [
        {
          id: 'webhookLogs',
          name: 'paths.webhookLogs',
          type: 'item',
          url: paths.main.webhookLogs,
        },
        {
          id: 'rankingLogs',
          name: 'paths.rankingLogs',
          type: 'item',
          url: paths.main.rankingLogs,
        },
      ],
    },
  ],
};
