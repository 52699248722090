import { ReactNode, useEffect } from 'react';
import PusherJS from 'pusher-js';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';

interface Props {
  children: ReactNode;
}

export const PusherProvider = ({ children }: Props) => {
  useEffect(() => {
    PusherJS.logToConsole = true;
    const pusher = new PusherJS(process.env.REACT_APP_PUSHER_KEY || '', {
      cluster: 'eu',
    });
    const nameChanel = 'triverna';
    const channel = pusher.subscribe(nameChanel);
    channel.bind('notification', (data: { message: string }) => {
      enqueueSnackbar({
        message: (
          <Box ml={1}>
            <Typography fontWeight={600}>Pusher</Typography>
            <Typography>{data?.message}</Typography>
          </Box>
        ),
        variant: 'info',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    });
    return () => {
      pusher.unsubscribe(nameChanel);
    };
  }, []);

  return children;
};
