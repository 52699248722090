import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutationCreateCancellationContent } from '../gql/createCancellationContent';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  refetch: () => void;
}

export const CreateCancellationContentDialog = ({
  open,
  handleCloseDialog,
  refetch,
}: Props) => {
  const { mutate } = useMutationCreateCancellationContent();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    plTitle: yup.string(),
    enTitle: yup.string(),
    plContent: yup.string(),
    enContent: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      plTitle: '',
      plContent: '',
      enTitle: '',
      enContent: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (value) => {
      mutate(
        {
          parameters: [
            {
              key: 'title',
              type: 'Translation',
              locale: 'pl',
              stringValue: value.plTitle,
            },
            {
              key: 'title',
              type: 'Translation',
              locale: 'en',
              stringValue: value.enTitle,
            },
            {
              key: 'content',
              type: 'Translation',
              locale: 'pl',
              stringValue: value.plContent,
            },
            {
              key: 'content',
              type: 'Translation',
              locale: 'en',
              stringValue: value.enContent,
            },
          ],
        },
        {
          onSuccess: () => {
            refetch();
            handleCloseDialog();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Dodaj Warunek</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid container spacing={1}>
            <Grid container item spacing={1} direction="column">
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="Polskie tłumaczenie - title"
                  name="plTitle"
                  id="plTitle"
                  value={formik.values.plTitle || ''}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="Angielskie tłumaczenie - title"
                  name="enTitle"
                  id="enTitle"
                  value={formik.values.enTitle || ''}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="Polskie tłumaczenie - content"
                  name="plContent"
                  id="plContent"
                  value={formik.values.plContent || ''}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="Angielskie tłumaczenie - content"
                  name="enContent"
                  id="enContent"
                  value={formik.values.enContent || ''}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  disabled={!formik?.isValid}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
