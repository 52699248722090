import { Theme } from '@mui/material';

export function Tabs(): Theme['components'] {
  return {
    MuiTabs: {
      styleOverrides: {
        vertical: {
          overflow: 'visible',
        },
      },
    },
  };
}
