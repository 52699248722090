import { lazy } from 'react';
import { Loadable } from 'components/Loadable';
import { AuthLayout } from 'layout/AuthLayout';

const AuthLogin = Loadable(
  lazy(() =>
    import('pages/login').then((mod) => ({
      default: mod.Login,
    })),
  ),
);

export const LoginRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />,
    },
  ],
};
