import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent } from 'react';

interface Props {
  placeholder: string;
  value: string;
  handleSearchInputChange: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
}

export const SearchBar = ({
  placeholder,
  value,
  handleSearchInputChange,
}: Props) => (
  <TextField
    placeholder={placeholder}
    onChange={(e) => handleSearchInputChange(e)}
    value={value}
    InputProps={{
      endAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
);
