import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateCityMutation,
  CreateCityMutationVariables,
} from './generated/createCity';

export const useMutationCreateCity = () => {
  const data = useMutation<
    CreateCityMutation,
    any,
    CreateCityMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(CREATE_CITY_MUTATION, params),
  });
  return data;
};

export const CREATE_CITY_MUTATION = gql`
  mutation createCity($parameters: [ConfigurationInput!]!) {
    createCity(parameters: $parameters) {
      id
      name
      latitude
      longitude
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
