import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRowModesModel } from 'components/DataGrid/hooks/useRowModesModel';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { DefaultGridToolbar } from 'components/DataGrid';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { CreateRoomTypeDialog } from './components/CreateRoomTypeDialog';
import { gqlDictionaries } from './gql';
import { useMutationUpdateRoomType } from './gql/updateRoomType';

interface Row {
  id?: number;
  pl: string;
  en: string;
  suite: boolean;
}

export const RoomTypesDictionary = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const updateRoomType = useMutationUpdateRoomType();
  // const removeRoomType = useMutationRemoveRoomType();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [sortBy, setSortBy] = useQueryParamsState(
    'sortBy',
    'room_type_translation.name',
  );
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'asc');
  const { data, isLoading } = useQuery({
    ...gqlDictionaries.getRoomTypes({
      offset: offset * limit,
      orderBy: sortBy,
      orderDir: sortDir,
      limit,
      phrase: searchPhrase,
    }),
  });
  const {
    handleRowEditStop,
    handleRowModesModelChange,
    rowModesModel,
    getActions,
  } = useRowModesModel();

  const client = useQueryClient();
  const refetch = useCallback(() => {
    client.refetchQueries({
      ...gqlDictionaries.getRoomTypes({
        offset: offset * limit,
        limit,
        orderBy: sortBy,
        orderDir: sortDir,
        phrase: searchPhrase,
      }),
    });
  }, [client, offset, limit, searchPhrase, sortBy, sortDir]);

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const processRowUpdate = useCallback(
    (newRow: Row, oldRow: Row) => {
      const { id, pl, en } = newRow;

      if (id && !isEqual(newRow, oldRow)) {
        updateRoomType.mutate(
          {
            id,
            parameters: [
              {
                key: 'name',
                type: 'Translation',
                locale: 'pl',
                stringValue: pl,
              },
              {
                key: 'name',
                type: 'Translation',
                locale: 'en',
                stringValue: en,
              },
              { key: 'suite', type: 'Boolean', boolValue: oldRow.suite },
            ],
          },
          {
            onSuccess: () => {
              refetch();
              enqueueSnackbar({
                message: t('message.success.changes-saved'),
                variant: 'success',
              });
            },
            onError: () => {
              enqueueSnackbar({
                message: t('message.error.error-occured'),
                variant: 'error',
              });
            },
          },
        );
        return newRow;
      }

      return oldRow;
    },
    [enqueueSnackbar, updateRoomType, t, refetch],
  );

  // const handleRemoveBedTypeRecord = (id: number | undefined) => {
  //   if (id) {
  //     removeRoomType.mutate(
  //       { id },
  //       {
  //         onSuccess: () => {
  //           refetch();
  //           enqueueSnackbar({
  //             message: t('message.success.changes-saved'),
  //             variant: 'success',
  //           });
  //         },
  //         onError: () => {
  //           enqueueSnackbar({
  //             message: t('message.error.error-occured'),
  //             variant: 'error',
  //           });
  //         },
  //       },
  //     );
  //   }
  // };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    editable: true,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.roomTypes.id'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'pl',
      headerName: t('pages.roomTypes.pl'),
      ...defaultProps,
    },
    {
      field: 'en',
      headerName: t('pages.roomTypes.en'),
      ...defaultProps,
      sortable: false,
    },
    {
      field: 'suite',
      headerName: t('pages.roomTypes.suite'),
      ...defaultProps,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams<Row>) => (
        <FormControlLabel
          control={
            <Switch
              checked={params.row.suite}
              onChange={
                () =>
                  processRowUpdate(params.row, {
                    ...params.row,
                    id: params.row.id,
                    suite: !params.row.suite,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          }
          label={<Typography>{t('pages.roomTypes.suite')}</Typography>}
          value="suite"
          name="suite"
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      headerName: '',
      getActions,
      flex: 1,
    },
    // Hide because of bussines decission
    // {
    //   field: 'remove',
    //   type: 'actions',
    //   align: 'right',
    //   headerName: '',
    //   flex: 1,
    //   maxWidth: 50,
    //   renderCell: (params: GridRenderCellParams<Row>) => (
    //     <IconButton onClick={() => handleRemoveBedTypeRecord(params.row?.id)}>
    //       <DeleteOutline />
    //     </IconButton>
    //   ),
    // },
  ];

  const rows = data?.getRoomTypes?.items?.map((row) => ({
    id: row.id,
    pl: row.pl.name,
    en: row.en.name,
    suite: row.suite,
  }));

  const handleSearchInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.preventDefault();
    setSearchPhrase(event.target.value);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    if (!item) {
      setSortBy('room_type_translation.name');
      setSortDir('asc');
      return;
    }
    setSortBy(
      item?.field === 'pl'
        ? 'room_type_translation.name'
        : `room_type.${item.field}`,
    );
    setSortDir(item.sort || 'desc');
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box>
              <SearchBar
                placeholder={t('pages.roomTypes.searchBarPlaceholder')}
                value={searchPhrase}
                handleSearchInputChange={handleSearchInputChange}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setOpenDialog(true)}>
              {t('add')}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            columns={columns}
            rows={rows || []}
            rowCount={data?.getRoomTypes.count || 0}
            autoHeight
            editMode="row"
            processRowUpdate={processRowUpdate}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            pageSizeOptions={[10, 25, 50, 75]}
            paginationMode="server"
            paginationModel={{ page: offset, pageSize: limit }}
            onPaginationModelChange={handlePagination}
            sortingMode="server"
            onSortModelChange={onSortModelChange}
            slots={{
              toolbar: DefaultGridToolbar,
            }}
          />
        </Grid>
        <CreateRoomTypeDialog
          refetch={refetch}
          open={openDialog}
          handleCloseDialog={() => setOpenDialog(false)}
        />
      </Grid>
    </div>
  );
};
