import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  CreateRoomTypeMutation,
  CreateRoomTypeMutationVariables,
} from './generated/createRoomType';

export const useMutationCreateRoomType = () => {
  const data = useMutation<
    CreateRoomTypeMutation,
    any,
    CreateRoomTypeMutationVariables
  >({
    mutationFn: (params) =>
      gqlClient.request(CREATE_ROOM_TYPE_MUTATION, params),
  });
  return data;
};

export const CREATE_ROOM_TYPE_MUTATION = gql`
  mutation createRoomType($parameters: [ConfigurationInput!]!) {
    createRoomType(parameters: $parameters) {
      id
      migrationId
      suite
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
