import { GlobalOutlined } from '@ant-design/icons';
import { MenuItem, Button, Typography, Menu } from '@mui/material';
import i18next from 'i18next';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LangChanger = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeLang = (value: string) => {
    handleClose();
    setTimeout(() => {
      i18next.changeLanguage(value);
    }, 200);
  };

  const langs = t('langs', { returnObjects: true }) || {};
  const activeLang = i18next.language;

  return (
    <>
      <Button
        size="small"
        startIcon={<GlobalOutlined />}
        sx={{
          textTransform: 'uppercase',
          color: 'text.primary',
          bgcolor: open ? 'grey.300' : 'grey.100',
          lineHeight: 2,
        }}
        onClick={handleClickListItem}
      >
        {activeLang}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        sx={(theme) => ({
          '& .MuiPaper-root': { marginTop: theme.spacing(1) },
        })}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {Object.entries(langs)?.map(([key, value]) => (
          <MenuItem
            key={key}
            selected={key === activeLang}
            onClick={() => handleChangeLang(key)}
          >
            {value}
            <Typography sx={{ textTransform: 'uppercase', pl: '2px' }}>
              ({key})
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
