import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from './generated/updateCurrency';

export const useMutationUpdateCurrency = () => {
  const data = useMutation<
    UpdateCompanyMutation,
    any,
    UpdateCompanyMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_CURRENCY_MUTATION, params),
  });
  return data;
};

export const UPDATE_CURRENCY_MUTATION = gql`
  mutation updateCompany($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateCurrency(id: $id, parameters: $parameters) {
      id
      migrationId
      name
      symbol
    }
  }
`;
