import { ThemeRoutes } from 'routes';
import { ThemeCustomization } from 'themes';
import { ScrollTop } from 'components/ScrollTop';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const iconSX = { marginRight: 8, fontSize: '1.15rem' };

const App = () => (
  <QueryClientProvider
    client={
      new QueryClient({
        defaultOptions: { queries: { placeholderData: (prev: any) => prev } },
      })
    }
  >
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeCustomization>
        <SnackbarProvider
          iconVariant={{
            success: <CheckCircleOutlined style={iconSX} />,
            error: <CloseCircleOutlined style={iconSX} />,
            warning: <WarningOutlined style={iconSX} />,
            info: <InfoCircleOutlined style={iconSX} />,
          }}
        >
          <ScrollTop>
            <ThemeRoutes />
          </ScrollTop>
        </SnackbarProvider>
      </ThemeCustomization>
    </LocalizationProvider>
  </QueryClientProvider>
);

export default App;
