import { LoadingButton } from '@mui/lab';
import { Grid, Stack, TextField, FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RankingWeight } from '../gql/rankingType';
import { useMutationSaveRankingType } from '../gql/saveRankingType';

interface Props {
  id: number;
  fields: { [name: string]: RankingWeight };
  initialValues: { [name: string]: string };
  isDraft?: boolean;
  handlePublish?: (id: number) => void;
  differents?: string[];
  refetch: () => void;
}
export const Form = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isPending } = useMutationSaveRankingType();
  const {
    fields = {},
    id,
    initialValues,
    isDraft,
    handlePublish,
    differents,
    refetch,
  } = props;

  const buildSchema = () =>
    Object.fromEntries(
      Object.entries(fields)?.map(([key, value]) => {
        if (value?.type === 'number') {
          let result = yup.number();
          const {
            required = false,
            max = 0,
            min = 0,
          } = value?.attributes || {};

          if (required) {
            result = result.required(t('form.error.required'));
          }
          if (min) {
            result = result.min(min, t('form.error.min', { count: min }));
          }
          if (max) {
            result = result.max(max, t('form.error.max', { count: max }));
          }
          return [key, result];
        }
        return [key, ''];
      }),
    );

  const validationSchema = yup.object(buildSchema());
  const formik = useFormik<Props['initialValues']>({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      mutate(
        {
          id,
          parameters:
            Object.entries(values)?.map(([key, value]) => ({
              key,
              stringValue: value?.toString(),
              type: 'String',
            })) || [],
        },
        {
          onSuccess: () => {
            refetch();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
      resetForm({ values });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={1}>
        {Object.entries(fields)?.map(([key, value]) => (
          <Grid item xs={12} md={2} key={key + id}>
            <Stack spacing={1}>
              <TextField
                size="small"
                label={value?.label}
                name={key}
                color="info"
                focused={differents?.includes(key)}
                type={value?.type}
                InputProps={{
                  inputProps: {
                    step: value?.attributes?.step,
                  },
                }}
                required={value?.attributes?.required}
                value={formik.values?.[key]}
                onChange={formik.handleChange}
              />
              {formik?.errors?.[key] && (
                <FormHelperText error>{formik?.errors?.[key]}</FormHelperText>
              )}
            </Stack>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          md={2}
          alignItems="center"
          textAlign="right"
          spacing={1}
        >
          <LoadingButton
            disabled={!formik?.isValid || !formik.dirty}
            loading={isPending}
            variant="contained"
            type="submit"
            fullWidth
          >
            {t('save')} Draft
          </LoadingButton>
          {isDraft && handlePublish ? (
            <LoadingButton
              disabled={formik.dirty}
              loading={isPending}
              variant="contained"
              onClick={() => handlePublish(id)}
              fullWidth
              sx={{ mt: 1 }}
            >
              Opublikuj Draft
            </LoadingButton>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
};
