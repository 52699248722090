import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HomeOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location || {};
  const items = pathname === '/' ? [''] : pathname.split('/');

  const getList = (): ReactElement[] => {
    let url = '';

    return items?.map((item, index) => {
      url += item ? `/${item}` : item;

      const name = t(`paths.${item}` as any, {
        defaultValue: capitalizeFirstLetter(item),
      });

      if (!item) {
        return (
          <Link key={item} underline="hover" color="inherit" href="/">
            <HomeOutlined />
          </Link>
        );
      }

      if (index + 1 < items?.length) {
        return (
          <Link key={item} underline="hover" color="inherit" href={url}>
            {name}
          </Link>
        );
      }

      return <Typography color="text.primary">{name}</Typography>;
    });
  };

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      {getList()}
    </MuiBreadcrumbs>
  );
};
