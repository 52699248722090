import { useTheme } from '@mui/material/styles';
import { LogoSection } from 'layout/MainLayout/components/Logo';
import { DrawerHeaderStyled } from './DrawerHeaderStyled';

interface Props {
  open: boolean;
}

export const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <LogoSection />
    </DrawerHeaderStyled>
  );
};
