import { Box, Button, Grid } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRowModesModel } from 'components/DataGrid/hooks/useRowModesModel';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { DefaultGridToolbar } from 'components/DataGrid';
import { CreateCityDialog } from './components/CreateCityDialog';
import { gqlDictionaries } from './gql';
import { useMutationUpdateCity } from './gql/updateCity';

interface Row {
  id?: number;
  name: string;
  latitude: number;
  longitude: number;
  pl: string;
  en: string;
}

export const CitiesDictionary = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const updateCity = useMutationUpdateCity();
  // const removeCity = useMutationRemoveCity();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [sortBy, setSortBy] = useQueryParamsState('sortBy', 'city.name');
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'asc');
  const { data, isLoading } = useQuery({
    ...gqlDictionaries.getCities({
      offset: offset * limit,
      orderBy: sortBy,
      orderDir: sortDir,
      limit,
      phrase: searchPhrase,
    }),
  });
  const {
    handleRowEditStop,
    handleRowModesModelChange,
    rowModesModel,
    getActions,
  } = useRowModesModel();

  const client = useQueryClient();
  const refetch = useCallback(() => {
    client.refetchQueries({
      ...gqlDictionaries.getCities({
        offset: offset * limit,
        limit,
        orderBy: sortBy,
        orderDir: sortDir,
        phrase: searchPhrase,
      }),
    });
  }, [client, offset, limit, searchPhrase, sortBy, sortDir]);

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const processRowUpdate = useCallback(
    (newRow: Row, oldRow: Row) => {
      const { id, name, latitude, longitude, pl, en } = newRow;

      if (id && !isEqual(newRow, oldRow)) {
        updateCity.mutate(
          {
            id,
            parameters: [
              { key: 'name', type: 'String', stringValue: name },
              {
                key: 'name',
                type: 'Translation',
                locale: 'pl',
                stringValue: pl,
              },
              {
                key: 'name',
                type: 'Translation',
                locale: 'en',
                stringValue: en,
              },
              { key: 'latitude', type: 'Float', floatValue: latitude },
              { key: 'longitude', type: 'Float', floatValue: longitude },
            ],
          },
          {
            onSuccess: () => {
              refetch();
              enqueueSnackbar({
                message: t('message.success.changes-saved'),
                variant: 'success',
              });
            },
            onError: () => {
              enqueueSnackbar({
                message: t('message.error.error-occured'),
                variant: 'error',
              });
            },
          },
        );
        return newRow;
      }

      return oldRow;
    },
    [enqueueSnackbar, updateCity, t, refetch],
  );

  // const handleRemoveCityRecord = (id: number | undefined) => {
  //   if (id) {
  //     removeCity.mutate(
  //       { id },
  //       {
  //         onSuccess: () => {
  //           refetch();
  //           enqueueSnackbar({
  //             message: t('message.success.changes-saved'),
  //             variant: 'success',
  //           });
  //         },
  //         onError: () => {
  //           enqueueSnackbar({
  //             message: t('message.error.error-occured'),
  //             variant: 'error',
  //           });
  //         },
  //       },
  //     );
  //   }
  // };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    editable: true,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.cities.id'),
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: t('pages.cities.name'),
      ...defaultProps,
    },
    {
      field: 'latitude',
      type: 'number',
      headerName: t('pages.cities.latitude'),
      ...defaultProps,
    },
    {
      field: 'longitude',
      type: 'number',
      headerName: t('pages.cities.longitude'),
      ...defaultProps,
    },
    {
      field: 'pl',
      headerName: t('pages.cities.pl'),
      ...defaultProps,
    },
    {
      field: 'en',
      headerName: t('pages.cities.en'),
      ...defaultProps,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      headerName: '',
      getActions,
      flex: 1,
    },
    // Hide because of bussines decission
    // {
    //   field: 'remove',
    //   type: 'actions',
    //   align: 'right',
    //   headerName: '',
    //   flex: 1,
    //   maxWidth: 50,
    //   renderCell: (params: GridRenderCellParams<Row>) => (
    //     <IconButton onClick={() => handleRemoveCityRecord(params.row?.id)}>
    //       <DeleteOutline />
    //     </IconButton>
    //   ),
    // },
  ];

  const rows = data?.getCities?.items?.map((row) => ({
    id: row.id,
    name: row.name,
    latitude: row.latitude,
    longitude: row.longitude,
    pl: row.pl.name,
    en: row.en.name,
  }));

  const handleSearchInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.preventDefault();
    setSearchPhrase(event.target.value);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    if (!item) {
      setSortBy('city.name');
      setSortDir('asc');
      return;
    }
    setSortBy(
      item?.field === 'pl' ? 'city_translation.name' : `city.${item.field}`,
    );
    setSortDir(item.sort || 'desc');
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box>
              <SearchBar
                placeholder={t('pages.cities.searchBarPlaceholder')}
                value={searchPhrase}
                handleSearchInputChange={handleSearchInputChange}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setOpenDialog(true)}>
              {t('add')}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <DataGrid
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            columns={columns}
            rows={rows || []}
            rowCount={data?.getCities.count || 0}
            autoHeight
            editMode="row"
            processRowUpdate={processRowUpdate}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            pageSizeOptions={[10, 25, 50, 75]}
            paginationMode="server"
            paginationModel={{ page: offset, pageSize: limit }}
            onPaginationModelChange={handlePagination}
            sortingMode="server"
            onSortModelChange={onSortModelChange}
            slots={{
              toolbar: DefaultGridToolbar,
            }}
          />
        </Grid>
        <CreateCityDialog
          refetch={refetch}
          open={openDialog}
          handleCloseDialog={() => setOpenDialog(false)}
        />
      </Grid>
    </div>
  );
};
