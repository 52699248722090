import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import { IsLoggedQuery } from './generated/gql';

export const getLogged = () => gqlClient.request<IsLoggedQuery>(IS_LOGGED);

export const IS_LOGGED = gql`
  query IsLogged {
    isLoggedUser
    isAdminLoggedUser
  }
`;
