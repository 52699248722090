import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetIncludeTypesQueryVariables,
  GetIncludeTypesQuery,
} from './generated/getIncludeTypes';

export const getIncludeTypes = (props: GetIncludeTypesQueryVariables) =>
  gqlClient.request<GetIncludeTypesQuery>(GET_INCLUDE_TYPES, props);

export const GET_INCLUDE_TYPES = gql`
  query getIncludeTypes(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getIncludeTypes(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        migrationId
        pl: translate(locale: "pl") {
          name
        }
        en: translate(locale: "en") {
          name
        }
      }
      count
    }
  }
`;
