import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetProvincesQuery,
  GetProvincesQueryVariables,
} from './generated/getProvinces';

export const getProvinces = (props: GetProvincesQueryVariables) =>
  gqlClient.request<GetProvincesQuery>(GET_PROVINCES, props);

export const GET_PROVINCES = gql`
  query getProvinces(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getProvinces(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        migrationId
        name
      }
      count
    }
  }
`;
