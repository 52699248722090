import { Button, Grid, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useState } from 'react';
import { useQueryParamsState } from 'utils/useQueryParamsState';
import { EditOutlined } from '@ant-design/icons';
import { DefaultGridToolbar } from 'components/DataGrid';
import { CreateAdminDialog } from './components/CreateAdminDialog';
import { gqlUsers } from './gql';
import { EditAdminDialog } from './components/EditAdminDialog';
import { Admin } from './gql/types';
import { UsersQuery } from './gql/generated/users';

const emptyUser = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  active: false,
  password: '',
};

export const Admins = () => {
  const [offset, setOffset] = useQueryParamsState('offset', 0);
  const [limit, setLimit] = useQueryParamsState('limit', 75);
  const [sortBy, setSortBy] = useQueryParamsState('sortBy', 'id');
  const [sortDir, setSortDir] = useQueryParamsState('sortDir', 'desc');
  const [openCreateAdminDialog, setOpenCreateAdminDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [userId, setUserIdToEdit] = useState(0);
  const [userToEdit, setUserToEdit] = useState<Admin>(emptyUser);

  const { data, isLoading } = useQuery({
    ...gqlUsers.getUsers({
      offset: offset * limit,
      limit,
      orderBy: sortBy,
      orderDir: sortDir,
      conditions: [
        {
          column: 'user.roles',
          value: 'ROLE_ADMIN',
          operator: 'like',
          type: 'AND',
        },
      ],
    }),
  });
  const client = useQueryClient();
  const refetch = () =>
    client.refetchQueries({
      ...gqlUsers.getUsers({
        offset: offset * limit,
        limit,
        orderBy: sortBy,
        orderDir: sortDir,
        conditions: [
          {
            column: 'user.roles',
            value: 'ROLE_ADMIN',
            operator: 'like',
            type: 'AND',
          },
        ],
      }),
    });

  const handleOpenEditUserDialog = () => {
    setOpenEditUserDialog(true);
  };

  const handleCloseEditUserDialog = () => {
    setOpenEditUserDialog(false);
  };

  const handlePagination = (paginationData: {
    page: number;
    pageSize: number;
  }) => {
    setOffset(paginationData.page);
    setLimit(paginationData.pageSize);
  };

  const onSortModelChange = ([item]: GridSortModel) => {
    if (!item) {
      setSortBy('id');
      setSortDir('desc');
      return;
    }
    setSortBy(item.field);
    setSortDir(item.sort || 'desc');
  };

  const defaultProps: Partial<GridColDef> = {
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('pages.users.id'),
      ...defaultProps,
      minWidth: 90,
      maxWidth: 90,
      resizable: false,
      sortable: true,
    },
    {
      field: 'firstName',
      headerName: t('pages.users.firstName'),
      ...defaultProps,
    },
    {
      field: 'lastName',
      headerName: t('pages.users.lastName'),
      ...defaultProps,
    },
    {
      field: 'email',
      headerName: t('pages.users.email'),
      ...defaultProps,
    },
    {
      field: 'phone',
      headerName: t('pages.users.phone'),
      ...defaultProps,
    },
    {
      field: 'migrationId',
      headerName: t('pages.users.migrationId'),
      ...defaultProps,
    },
    {
      field: 'updatedAt',
      headerName: t('pages.users.updatedAt'),
      ...defaultProps,
    },
    {
      field: 'identifier',
      headerName: t('pages.users.identifier'),
      ...defaultProps,
    },
    {
      field: 'active',
      headerName: t('pages.hotels.active.header'),
      type: 'boolean',
      ...defaultProps,
    },
    {
      field: 'edit',
      headerName: t('pages.users.edit'),
      ...defaultProps,
      renderCell: () => (
        <IconButton onClick={handleOpenEditUserDialog}>
          <EditOutlined />
        </IconButton>
      ),
    },
  ];

  const rows =
    data?.users?.items?.map((row) => ({
      id: row.id,
      firstName: row.firstName,
      lastName: row.lastName,
      email: row.email,
      phone: row.phone,
      migrationId: row.migrationId,
      active: row.active,
      updatedAt: row.updatedAt,
      identifier: row.identifier,
    })) || [];

  const setUser = (param: {
    id: any;
    row: NonNullable<UsersQuery['users']['items']>[0];
  }) => {
    const clickedUser: Admin = {
      firstName: param.row.firstName || '',
      lastName: param.row.lastName || '',
      email: param.row.email || '',
      phone: param.row.phone || '',
      active: param.row.active,
      password: '',
    };

    setUserIdToEdit(param.id);
    setUserToEdit(clickedUser);
  };

  return (
    <Grid width="100%">
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <h2>{t('paths.admins')}</h2>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => setOpenCreateAdminDialog(true)}
          >
            {t('add')}
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        autoHeight
        pageSizeOptions={[10, 25, 50, 75]}
        paginationMode="server"
        paginationModel={{ page: offset, pageSize: limit }}
        onPaginationModelChange={handlePagination}
        rowCount={data?.users.count || 0}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        onRowClick={setUser}
        disableRowSelectionOnClick
        disableColumnMenu
        slots={{
          toolbar: DefaultGridToolbar,
        }}
      />
      <CreateAdminDialog
        open={openCreateAdminDialog}
        handleCloseDialog={() => setOpenCreateAdminDialog(false)}
        refetch={refetch}
      />
      <EditAdminDialog
        key={userId}
        open={openEditUserDialog}
        id={userId}
        handleCloseDialog={handleCloseEditUserDialog}
        initialValues={userToEdit}
        refetch={refetch}
      />
    </Grid>
  );
};
