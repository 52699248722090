import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables,
} from './generated/updateUserGroup';

export const useMutationUpdateUserGroup = () => {
  const data = useMutation<
    UpdateUserGroupMutation,
    any,
    UpdateUserGroupMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_USER_GROUP, params),
  });
  return data;
};

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($id: Int, $parameters: [ConfigurationInput]) {
    updateUserGroup(id: $id, parameters: $parameters) {
      id
    }
  }
`;
