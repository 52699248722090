import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutationCreateIncludeType } from '../gql/createIncludeType';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  refetch: () => void;
}

export const CreateIncludeTypeDialog = ({
  open,
  handleCloseDialog,
  refetch,
}: Props) => {
  const { mutate } = useMutationCreateIncludeType();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    en: yup.string(),
    pl: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      pl: '',
      en: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (value) => {
      mutate(
        {
          parameters: [
            {
              key: 'name',
              type: 'Translation',
              locale: 'pl',
              stringValue: value.pl,
            },
            {
              key: 'name',
              type: 'Translation',
              locale: 'en',
              stringValue: value.en,
            },
          ],
        },
        {
          onSuccess: () => {
            refetch();
            handleCloseDialog();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Dodaj Typ</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid container spacing={1}>
            <Grid container item spacing={1} direction="column">
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="polskie tłumaczenie"
                  name="pl"
                  id="pl"
                  value={formik.values.pl || ''}
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item style={{ marginTop: '8px' }}>
                <TextField
                  label="angielskie tłumaczenie"
                  name="en"
                  id="en"
                  value={formik.values.en || ''}
                  onChange={formik.handleChange}
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  disabled={!formik?.isValid}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
