import { Theme } from '@mui/material';

export function CardContent(): Theme['components'] {
  return {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 20,
          '&:last-child': {
            paddingBottom: 20,
          },
        },
      },
    },
  };
}
