import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetPermissionsQuery,
  GetPermissionsQueryVariables,
} from './generated/getPermission';

export const getPermissions = (props?: GetPermissionsQueryVariables) =>
  gqlClient.request<GetPermissionsQuery>(GET_PERMISSIONS, props);

export const GET_PERMISSIONS = gql`
  query getPermissions {
    getPermissions(
      criteria: {
        limit: 2000
        offset: 0
        orderBy: "permission.fullName"
        orderDir: "asc"
      }
    ) {
      items {
        id
        module
        action
        fullName
      }
      count
    }
  }
`;
