import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Switch,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useState } from 'react';
import { Admin } from '../gql/types';
import { useMutationUpdateUser } from '../gql/updateUser';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  id: number;
  initialValues: Admin;
  refetch: () => void;
}

export const EditAdminDialog = ({
  open,
  id,
  initialValues,
  refetch,
  ...props
}: Props) => {
  const { mutate, isPending } = useMutationUpdateUser();
  const [createNewPassword, setCreateNewPassword] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    firstName: yup.string().notRequired(),
    lastName: yup.string().notRequired(),
    email: yup.string().email('Invalid email'),
    phone: yup.string().notRequired(),
    password: yup.string().notRequired(),
    active: yup.boolean().notRequired(),
  });

  const handleCloseDialog = () => {
    props?.handleCloseDialog();
    setCreateNewPassword(false);
    formik.resetForm();
  };

  const formik = useFormik<Props['initialValues']>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ email, firstName, lastName, phone, active, password }) => {
      const parameters = [
        {
          key: 'firstName',
          stringValue: firstName,
          type: 'String',
        },
        {
          key: 'lastName',
          stringValue: lastName,
          type: 'String',
        },
        {
          key: 'email',
          stringValue: email,
          type: 'String',
        },
        {
          key: 'phone',
          stringValue: phone,
          type: 'String',
        },
        {
          key: 'active',
          boolValue: active,
          type: 'Boolean',
        },
      ];

      if (password) {
        parameters.push({
          key: 'password',
          stringValue: password,
          type: 'String',
        });
      }

      mutate(
        {
          id,
          parameters,
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            refetch();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseDialog}>
      <DialogTitle>{t('editing')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid direction="column" container spacing={3}>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.firstName')}
                name="firstName"
                id="firstName"
                value={formik.values.firstName || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.lastName')}
                name="lastName"
                id="lastName"
                value={formik.values.lastName || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.email')}
                type="email"
                name="email"
                id="email"
                value={formik.values.email || ''}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.phone')}
                type="string"
                name="phone"
                id="phone"
                value={formik.values.phone || ''}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item>
              {createNewPassword ? (
                <TextField
                  fullWidth
                  label={t('pages.users.password')}
                  type="password"
                  name="password"
                  id="password"
                  value={formik.values.password || ''}
                  onChange={formik.handleChange}
                />
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setCreateNewPassword(true)}
                >
                  {t('pages.users.create-new-password')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch />}
                label={t('pages.users.user-status')}
                onChange={formik.handleChange}
                checked={formik.values.active}
                name="active"
              />
            </Grid>
            <Grid container item spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  disabled={!formik?.isValid}
                  loading={isPending}
                  variant="contained"
                  type="submit"
                >
                  {t('save')}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
