import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetCancellationContentsQuery,
  GetCancellationContentsQueryVariables,
} from './generated/getCancellationContents';

export const getCancellationContents = (
  props: GetCancellationContentsQueryVariables,
) =>
  gqlClient.request<GetCancellationContentsQuery>(
    GET_CANCELLATION_CONTENTS,
    props,
  );

export const GET_CANCELLATION_CONTENTS = gql`
  query getCancellationContents(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getCancellationContents(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        migrationId
        pl: translate(locale: "pl") {
          title
          content
        }
        en: translate(locale: "en") {
          title
          content
        }
      }
      count
    }
  }
`;
