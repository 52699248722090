import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import {
  GetCurrenciesQueryVariables,
  GetCurrenciesQuery,
} from './generated/getCurrencies';

export const getCurrencies = (props: GetCurrenciesQueryVariables) =>
  gqlClient.request<GetCurrenciesQuery>(GET_CURRENCIES, props);

export const GET_CURRENCIES = gql`
  query getCurrencies(
    $limit: Int
    $offset: Int
    $phrase: String
    $orderBy: String
    $orderDir: String
  ) {
    getCurrencies(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
    ) {
      items(phrase: $phrase) {
        id
        migrationId
        name
        symbol
      }
      count
    }
  }
`;
