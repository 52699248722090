import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  RemoveUserGroupMutation,
  RemoveUserGroupMutationVariables,
} from './generated/removeUserGroup';

export const useMutationRemoveUserGroup = () => {
  const data = useMutation<
    RemoveUserGroupMutation,
    any,
    RemoveUserGroupMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(REMOVE_USER_GROUP, params),
  });
  return data;
};

export const REMOVE_USER_GROUP = gql`
  mutation RemoveUserGroup($id: Int!) {
    removeUserGroup(id: $id)
  }
`;
