import { Theme } from '@mui/material';

export function LinearProgress(): Theme['components'] {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 4,
          borderRadius: 100,
        },
        bar: {
          borderRadius: 100,
        },
      },
    },
  };
}
