import { gql } from 'graphql-request';
import { gqlClient } from 'api/gql/clients/gql';
import { HotelsQuery, HotelsQueryVariables } from './generated/hotels';

export const getHotels = (props: HotelsQueryVariables) =>
  gqlClient.request<HotelsQuery>(GET_HOTELS, props);

export const GET_HOTELS = gql`
  query hotels(
    $limit: Int
    $offset: Int
    $orderBy: String
    $orderDir: String
    $conditions: [ConditionInput]
    $phrase: String
  ) {
    hotels(
      criteria: {
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
      }
      conditions: $conditions
    ) {
      items(phrase: $phrase) {
        id
        translate {
          name
        }
        migrationId
        rooms {
          id
        }
        offers {
          id
        }
        active
        expose {
          dedge
          google
          nekera
        }
        taxEnabled
      }
      count
    }
  }
`;
