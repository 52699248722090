import { useMutation } from '@tanstack/react-query';
import { gqlClient } from 'api/gql/clients/gql';
import { gql } from 'graphql-request';
import {
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
} from './generated/updateCategory';

export const useMutationUpdateCategory = () => {
  const data = useMutation<
    UpdateCategoryMutation,
    any,
    UpdateCategoryMutationVariables
  >({
    mutationFn: (params) => gqlClient.request(UPDATE_CATEGORY_MUTATION, params),
  });
  return data;
};

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategory($id: Int!, $parameters: [ConfigurationInput!]!) {
    updateCategory(id: $id, parameters: $parameters) {
      id
      migrationId
      defaultMonth
      defaultYear
      pl: translate(locale: "pl") {
        name
      }
      en: translate(locale: "en") {
        name
      }
    }
  }
`;
