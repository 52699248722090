import { createSlice } from '@reduxjs/toolkit';

export interface StoreMenu {
  openItem: string;
  defaultId: string;
  openComponent: string;
  drawerOpen: boolean;
  componentDrawerOpen: boolean;
}

const initialState: StoreMenu = {
  openItem: 'dashboard',
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: true,
  componentDrawerOpen: true,
};

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },
    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
  },
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer } =
  menu.actions;
