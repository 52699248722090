import { Theme } from '@mui/material';
import { Badge } from './Badge';
import { Button } from './Button';
import { CardContent } from './CardContent';
import { Checkbox } from './Checkbox';
import { Chip } from './Chip';
import { IconButton } from './IconButton';
import { InputLabel } from './InputLabel';
import { LinearProgress } from './LinearProgress';
import { Link } from './Link';
import { ListItemIcon } from './ListItemIcon';
import { OutlinedInput } from './OutlinedInput';
import { Tab } from './Tab';
import { TableCell } from './TableCell';
import { Tabs } from './Tabs';
import { Typography } from './Typography';
import { TablePagination } from './TablePagination';
import { TableRow } from './TableRow';
import { TableBody } from './TableBody';
import { DataGrid } from './DataGrid';

export const ComponentsOverrides = (theme: Theme): Theme['components'] => ({
  ...Button(theme),
  ...Badge(theme),
  ...CardContent(),
  ...Checkbox(theme),
  ...Chip(),
  ...IconButton(theme),
  ...InputLabel(theme),
  ...LinearProgress(),
  ...Link(),
  ...ListItemIcon(),
  ...OutlinedInput(theme),
  ...Tab(theme),
  ...TableCell(theme),
  ...Tabs(),
  ...Typography(),
  ...TablePagination(),
  ...TableRow(),
  ...TableBody(theme),
  ...DataGrid(theme),
});
