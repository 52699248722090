import { EditTwoTone } from '@ant-design/icons';
import { CancelOutlined, Save } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useRowModesModel = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getActions = ({ id }: GridRowParams<any>) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={
            <Tooltip title={t('save')}>
              <Save />
            </Tooltip>
          }
          label="Save"
          sx={{
            color: 'primary.main',
          }}
          onClick={handleSaveClick(id)}
        />,

        <GridActionsCellItem
          icon={
            <Tooltip title={t('cancel')}>
              <CancelOutlined />
            </Tooltip>
          }
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          sx={{
            color: 'red',
          }}
        />,
      ];
    }

    return [
      <Tooltip title={t('edit')}>
        <GridActionsCellItem
          icon={<EditTwoTone />}
          size="medium"
          label="Edit"
          onClick={handleEditClick(id)}
          color="primary"
        />
      </Tooltip>,
    ];
  };

  return {
    handleRowModesModelChange,
    handleCancelClick,
    handleSaveClick,
    handleEditClick,
    handleRowEditStop,
    rowModesModel,
    getActions,
  };
};
