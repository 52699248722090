import { Typography } from '@mui/material';
import { TableSegments } from './components/TableSegments';
import { TableTricoins } from './components/TableTricoins';

export const Segments = () => (
  <div>
    <TableSegments />
    <Typography variant="h5" my="30px">
      TRIcoins
    </Typography>
    <TableTricoins />
  </div>
);
