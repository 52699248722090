import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutationUpdateContact } from '../gql/updateContact';
import { useMutationUpdateUser } from '../gql/updateUser';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  id: number;
  initialValues: any;
  refetch: () => void;
}

export const EditContactDialog = ({
  open,
  handleCloseDialog,
  id,
  initialValues,
  refetch,
}: Props) => {
  const { mutate, isPending } = useMutationUpdateContact();
  const { mutate: mutateUpdateUser, isPending: isPendingUpdateUser } =
    useMutationUpdateUser();
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email('Invalid email'),
  });

  const formik = useFormik<Props['initialValues']>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const mutateUpdateUserVariables = {
        firstName: values.firstName,
        lastName: values.lastName,
      };
      if (initialValues.userId) {
        mutateUpdateUser(
          {
            id: initialValues.userId,
            parameters:
              Object.entries(mutateUpdateUserVariables)?.map(
                ([key, value]) => ({
                  key,
                  type: 'String',
                  stringValue: value?.toString(),
                }),
              ) || [],
          },
          {
            onSuccess: () => {
              handleCloseDialog();
              refetch();
            },
            onError: () => {
              enqueueSnackbar({
                message: t('message.error.error-occured'),
                variant: 'error',
              });
            },
          },
        );
      }

      const mutateVariables = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };

      mutate(
        {
          id,
          parameters:
            Object.entries(mutateVariables)?.map(([key, value]) => ({
              key,
              type: 'String',
              stringValue: value?.toString(),
            })) || [],
        },
        {
          onSuccess: () => {
            handleCloseDialog();
            refetch();
            enqueueSnackbar({
              message: t('message.success.changes-saved'),
              variant: 'success',
            });
          },
          onError: () => {
            enqueueSnackbar({
              message: t('message.error.error-occured'),
              variant: 'error',
            });
          },
        },
      );
    },
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseDialog}>
      <DialogTitle>{t('pages.users.edit-contact')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '8px' }}>
          <Grid direction="column" container spacing={3}>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.firstName')}
                name="firstName"
                id="firstName"
                value={formik.values.firstName || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.lastName')}
                name="lastName"
                id="lastName"
                value={formik.values.lastName || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label={t('pages.users.email')}
                type="email"
                name="email"
                id="email"
                value={formik.values.email || ''}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent="flex-end" mt={1}>
            <Grid item>
              <LoadingButton
                disabled={!formik?.isValid}
                loading={isPending || isPendingUpdateUser}
                variant="contained"
                type="submit"
              >
                {t('save')}
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleCloseDialog}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
